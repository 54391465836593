import { Box, Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { generateAllSwaps } from "../../utils/utils";

interface PropsType {
    amountNumber: number;
    subType: number;
    handlePushNumber: (number: string) => void;
    handleAddMultiNumber: (number: string[]) => void;
    deleteLastAdded: () => void;
}

const PadNumber = ({ amountNumber, subType, handlePushNumber, handleAddMultiNumber, deleteLastAdded }: PropsType) => {
    const [number, setNumber] = useState<string[]>([]);

    useEffect(() => {
        if(amountNumber <= number.length)
        {
            let sendNumber: string = "";
            
            number.forEach((element: string) => {
                sendNumber = `${sendNumber}${element}`;
            });

            const allSwapsNumber: string[] = generateAllSwaps(sendNumber);
            if(amountNumber === 3 && subType === 2)
            {
                handleAddMultiNumber(allSwapsNumber);
            }
            else if(amountNumber === 2 && subType === 2 || amountNumber === 2 && subType === 4 || amountNumber === 2 && subType === 6)
            {
                handleAddMultiNumber(allSwapsNumber);
            }
            else
            {
                handleAddMultiNumber([sendNumber]);
            }
            
            setTimeout(() => setNumber([]), 200);
        }
    }, [number, amountNumber]);

    const handleAddNumber = (num: string) => {
        const tmp_number = [
            ...number
        ];
        tmp_number.push(num);
        if(amountNumber <= number.length)
        {
            return;
        }
        setNumber(tmp_number);
    }

    const handleDelelteLastNumber = () => {
        const tmp_number = [
            ...number
        ];

        tmp_number.pop();
        setNumber(tmp_number);
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Box sx={{
                    backgroundColor: 'white',
                    border: '1px solid #bfbebe',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignContent: 'center',
                    py: .7,
                    borderRadius: 1.5,
                    transition: 'all .2s',
                    cursor: 'default'
                }} className="no-select">
                    {
                        Array.from({ length: amountNumber }, (_, i) => (
                            <Box
                                key={i}
                                sx={{
                                    width: '50px',
                                    height: '50px',
                                    borderRadius: 10,
                                    backgroundColor: '#f5f5f5',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    mx: 0.5,
                                    boxShadow: 'inset 0 0 4px rgba(0, 0, 0, 0.1)',
                                    userSelect: 'none !important'
                                }}
                            >
                                <Typography fontSize={28} sx={{ userSelect: 'none !important' }}>
                                    {
                                        number[i] ? `${number[i]}` : ``
                                    }
                                </Typography>
                            </Box>
                        ))
                    }
                </Box>
            </Grid>
            {
                [1,2,3,4,5,6,7,8,9].map((element: number) => {
                    return (
                        <Grid item xs={4}key={`pad-${element}`}>
                            <Button fullWidth
                                sx={{
                                    backgroundColor: 'white',
                                    border: '1px solid #bfbebe',
                                    borderRadius: '4px',
                                    transition: 'all .2s',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: '#f2f7ff',
                                        fontWeight: 'bold'
                                    }
                                }}
                                onClick={() => handleAddNumber(`${element}`)}
                                disableRipple
                                disableFocusRipple
                            >
                                <Typography color={'black'}>
                                    {element}
                                </Typography>
                            </Button>
                        </Grid>
                    )
                })
            }
            <Grid item xs={4} display={'flex'} justifyContent={'flex-end'}>
                <Button fullWidth
                    sx={{
                        backgroundColor: 'rgb(255, 216, 216)',
                        border: '1px solid rgba(255, 126, 126, 0.7)',
                        borderRadius: '4px',
                        transition: 'all .2s',
                        cursor: 'pointer',
                        '&:hover': {
                            backgroundColor: 'rgb(255, 216, 216)',
                            fontWeight: 'bold'
                        },
                    }}
                    disableRipple
                    disableFocusRipple
                    onClick={deleteLastAdded}
                >
                    <Typography fontSize={12} color={'rgb(255, 126, 126)'}>
                        ลบล่าสุด
                    </Typography>
                </Button>
            </Grid>
            <Grid item xs={4} display={'flex'} justifyContent={'flex-end'}>
                <Button fullWidth
                    sx={{
                        backgroundColor: 'white',
                        border: '1px solid #bfbebe',
                        borderRadius: '4px',
                        transition: 'all .2s',
                        cursor: 'pointer',
                        '&:hover': {
                            backgroundColor: '#f2f7ff',
                            fontWeight: 'bold'
                        }
                    }}
                    onClick={() => handleAddNumber(`0`)}
                    disableRipple
                    disableFocusRipple
                >
                    <Typography color={'black'}>
                        0
                    </Typography>
                </Button>
            </Grid>
            <Grid item xs={4} display={'flex'} justifyContent={'flex-end'}>
                <Button
                    sx={{
                        backgroundColor: 'rgb(255, 216, 216)',
                        border: '1px solid rgba(255, 126, 126, 0.7)',
                        borderRadius: '0px 4px 4px 0px',
                        transition: 'all .2s',
                        cursor: 'pointer',
                        '&:hover': {
                            backgroundColor: 'rgb(255, 216, 216)',
                            fontWeight: 'bold'
                        },
                        width: 'calc(100% - 18px)',
                        minWidth: '0px !important',
                        position: 'relative',
                        overflow: 'visible',
                        '&::before': {
                            content: '""',
                            position: 'absolute',
                            top: '50%',
                            left: '-14px',
                            width: '27.6571px',
                            height: '27.6571px',
                            background: 'rgb(255, 216, 216)',
                            borderStyle: 'solid',
                            borderColor: 'rgba(255, 126, 126, 0.7)',
                            borderWidth: '0px 0px 1px 1px',
                            borderRadius: '4px 28px 4px 4px',
                            transition: 'all 0.4s ease 0s',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    }}
                    disableRipple
                    disableFocusRipple
                    onClick={handleDelelteLastNumber}
                >
                    <Typography fontSize={12} color={'rgb(255, 126, 126)'}>
                        X
                    </Typography>
                </Button>
            </Grid>
        </Grid>
    )
}

export default PadNumber;