import { Box, Button, Container, Divider, Grid, Typography } from "@mui/material";
import { checkedEnableNumberType, inputNumberListType } from "../../types";
import PadNumber from "./PadNumber";
import NumberSet from "./NumberSet";
import { useEffect, useState } from "react";
import ButtonBackground from "./../../assets/images/bg/button-keypad-type-123-light-bg.png";
import ButtonLightBackground from "./../../assets/images/bg/button-bg-light-theme-123-light.png";
import { generateRandomString } from "../../utils/utils";
import toast, { Toaster } from 'react-hot-toast';
import { useMediaQuery } from "react-responsive";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

interface PropsType {
    inputNumber: inputNumberListType[],
    setInputNumber: (inputNumber: inputNumberListType[]) => void;
    logsSeed: string[],
    setLogsSeed: (inputNumber: string[]) => void;
    deleteLastAdded: () => void;
    openTabInputPrice: boolean;
    setOpenTabInputPrice: (state: boolean) => void;
    checkedEnableNumber: checkedEnableNumberType;
}

const InputNumber = ({ inputNumber, setInputNumber, logsSeed, setLogsSeed, deleteLastAdded, openTabInputPrice, setOpenTabInputPrice, checkedEnableNumber }: PropsType) => {
    const navigate = useNavigate();
    const isDesktop = useMediaQuery({
        query: '(min-width: 900px)'
    });

    const [typeInput, setTypeInput] = useState<number>(1);
    const [amountNumberState, setAmountNumberState] = useState<number>(3);
    const [subTypeInput, setSubTypeInput] = useState<number>(1);

    const handleChangeTypeInput = (type: number) => {
        setTypeInput(type);
    }

    const handleChangeAmountNumber = (amount: number) => {
        setAmountNumberState(amount);
        setSubTypeInput(1);
    }

    const handleChangeSubType = (type: number) => {
        setSubTypeInput(type);
    }

    const handleAddSingleNumber = (number: string) => {
        const randomSeed: string = generateRandomString(20);
        const tmp_inputNumber: inputNumberListType[] = [
            ...inputNumber
        ];

        tmp_inputNumber.push({
            number: number,
            subtype: subTypeInput,
            amountNumberState: amountNumberState,
            seed: randomSeed
        });

        const tmp_logsSeed: string[] = [
            ...logsSeed
        ];
        tmp_logsSeed.push(randomSeed);

        setInputNumber(tmp_inputNumber);
        setLogsSeed(tmp_logsSeed);
    }

    const handleAddMultiNumber = (number: string[]) => {
        console.log(`AMOUNT NUMBER STATE: ${amountNumberState}, TYPE: ${typeInput}, SUBTYPE: ${subTypeInput}`)
        const duplicate_number: string[] = [];
        const randomSeed: string = generateRandomString(20);
        const tmp_inputNumber: inputNumberListType[] = [
            ...inputNumber
        ];

        number.forEach((element: string) => {
            let findDuplicateNumber = -1;
            if(amountNumberState === 2 && subTypeInput === 6 || amountNumberState === 2 && subTypeInput === 5)
            {
                findDuplicateNumber = tmp_inputNumber.findIndex((oldNumber: inputNumberListType) => {
                    return oldNumber.number === element && oldNumber.amountNumberState === amountNumberState && oldNumber.subtype === 1
                });
                if(findDuplicateNumber !== -1)
                {
                    findDuplicateNumber = -1;
                    duplicate_number.push(element);
                }
                else
                {
                    tmp_inputNumber.push({
                        number: element,
                        subtype: 1,
                        amountNumberState: amountNumberState,
                        seed: randomSeed
                    });
                }

                findDuplicateNumber = tmp_inputNumber.findIndex((oldNumber: inputNumberListType) => {
                    return oldNumber.number === element && oldNumber.amountNumberState === amountNumberState && oldNumber.subtype === 3
                });
                if(findDuplicateNumber !== -1)
                {
                    findDuplicateNumber = -1;
                    duplicate_number.push(element);
                }
                else
                {
                    tmp_inputNumber.push({
                        number: element,
                        subtype: 3,
                        amountNumberState: amountNumberState,
                        seed: randomSeed
                    });
                }
            }
            else if(amountNumberState === 2 && subTypeInput === 2)
            {
                findDuplicateNumber = tmp_inputNumber.findIndex((oldNumber: inputNumberListType) => {
                    return oldNumber.number === element && oldNumber.amountNumberState === amountNumberState && oldNumber.subtype === 1
                });
                if(findDuplicateNumber !== -1)
                {
                    findDuplicateNumber = -1;
                    duplicate_number.push(element);
                }
                else
                {
                    tmp_inputNumber.push({
                        number: element,
                        subtype: 1,
                        amountNumberState: amountNumberState,
                        seed: randomSeed
                    });
                }
            }
            else if(amountNumberState === 2 && subTypeInput === 4)
            {
                findDuplicateNumber = tmp_inputNumber.findIndex((oldNumber: inputNumberListType) => {
                    return oldNumber.number === element && oldNumber.amountNumberState === amountNumberState && oldNumber.subtype === 3
                });
                if(findDuplicateNumber !== -1)
                {
                    findDuplicateNumber = -1;
                    duplicate_number.push(element);
                }
                else
                {
                    tmp_inputNumber.push({
                        number: element,
                        subtype: 3,
                        amountNumberState: amountNumberState,
                        seed: randomSeed
                    });
                }
            }
            else if(amountNumberState === 3 && subTypeInput === 2)
            {
                findDuplicateNumber = tmp_inputNumber.findIndex((oldNumber: inputNumberListType) => {
                    return oldNumber.number === element && oldNumber.amountNumberState === amountNumberState && oldNumber.subtype === 1
                });
                if(findDuplicateNumber !== -1)
                {
                    findDuplicateNumber = -1;
                    duplicate_number.push(element);
                }
                else
                {
                    tmp_inputNumber.push({
                        number: element,
                        subtype: 1,
                        amountNumberState: amountNumberState,
                        seed: randomSeed
                    });
                }
            }
            else if(amountNumberState === 3 && subTypeInput === 4)
            {
                findDuplicateNumber = tmp_inputNumber.findIndex((oldNumber: inputNumberListType) => {
                    return oldNumber.number === element && oldNumber.amountNumberState === amountNumberState && oldNumber.subtype === 1
                });
                if(findDuplicateNumber !== -1)
                {
                    findDuplicateNumber = -1;
                    duplicate_number.push(element);
                }
                else
                {
                    tmp_inputNumber.push({
                        number: element,
                        subtype: 1,
                        amountNumberState: amountNumberState,
                        seed: randomSeed
                    });
                }

                findDuplicateNumber = tmp_inputNumber.findIndex((oldNumber: inputNumberListType) => {
                    return oldNumber.number === element && oldNumber.amountNumberState === amountNumberState && oldNumber.subtype === 3
                });
                if(findDuplicateNumber !== -1)
                {
                    findDuplicateNumber = -1;
                    duplicate_number.push(element);
                }
                else
                {
                    tmp_inputNumber.push({
                        number: element,
                        subtype: 3,
                        amountNumberState: amountNumberState,
                        seed: randomSeed
                    });
                }
            }
            else
            {
                findDuplicateNumber = tmp_inputNumber.findIndex((oldNumber: inputNumberListType) => {
                    return oldNumber.number === element && oldNumber.amountNumberState === amountNumberState && oldNumber.subtype === subTypeInput
                });
                if(findDuplicateNumber !== -1)
                {
                    findDuplicateNumber = -1;
                    duplicate_number.push(element);
                }
                else
                {
                    tmp_inputNumber.push({
                        number: element,
                        subtype: subTypeInput,
                        amountNumberState: amountNumberState,
                        seed: randomSeed
                    });
                }
            }
        });

        const tmp_logsSeed: string[] = [
            ...logsSeed
        ];
        tmp_logsSeed.push(randomSeed);

        setInputNumber(tmp_inputNumber);
        setLogsSeed(tmp_logsSeed);

        if(duplicate_number.length > 0)
        {
            let text_number = '';
            duplicate_number.forEach((element: string, index: number) => {
                if(index === 0)
                {
                    text_number = element
                }
                else
                {
                    text_number = `${text_number}, ${element}`
                }
            });

            Swal.fire({
                icon: 'warning',
                title: 'แจ้งเตือน',
                text: `เลข ${text_number} ซ้ำ ไม่สามารถเพิ่มได้ค่ะ`
            });
        }
    }

    return (
        <>
            <Toaster />
            <Box sx={
                {
                    display: `flex`,
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    maxHeight: '90dvh',
                    height: `90dvh`
                }
            }>
                <Box height={`100%`} sx={{ overflowY: 'scroll' }}>
                    <Grid container spacing={1} mb={2}>
                        <Grid item xs={6}>
                            <Button fullWidth
                                sx={{
                                    backgroundColor: typeInput === 1 ? 'none' : 'white',
                                    backgroundImage: typeInput === 1 ? `url('${ButtonBackground}')` : 'none',
                                    backgroundSize: 'cover',
                                    border: '1px solid #bfbebe',
                                    borderRadius: '4px',
                                    transition: 'all .2s',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: '#f2f7ff',
                                        fontWeight: 'bold'
                                    }
                                }}
                                disableRipple
                                disableFocusRipple
                                onClick={() => handleChangeTypeInput(1)}
                            >
                                <Typography color={typeInput === 1 ? 'white' : 'black'} fontWeight={`bold`}>
                                    กดเลือกเอง
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button fullWidth
                                sx={{
                                    backgroundColor: typeInput === 2 ? 'none' : 'white',
                                    backgroundImage: typeInput === 2 ? `url('${ButtonBackground}')` : 'none',
                                    backgroundSize: 'cover',
                                    border: '1px solid #bfbebe',
                                    borderRadius: '4px',
                                    transition: 'all .2s',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: '#f2f7ff',
                                        fontWeight: 'bold'
                                    }
                                }}
                                disableRipple
                                disableFocusRipple
                                onClick={() => handleChangeTypeInput(2)}
                            >
                                <Typography color={typeInput === 2 ? 'white' : 'black'} fontWeight={`bold`}>
                                    ชุดตัวเลข
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider>
                                <Typography fontSize={10}>
                                    ตัวเลือก
                                </Typography>
                            </Divider>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                {
                                    (checkedEnableNumber.first_three_number || checkedEnableNumber.last_three_number) && <Grid item xs={6} md={4}>
                                        <Button fullWidth
                                            sx={{
                                                backgroundColor: amountNumberState === 3 ? 'none' : 'white',
                                                backgroundImage: amountNumberState === 3 ? `url('${ButtonBackground}')` : 'none',
                                                backgroundSize: 'cover',
                                                border: '1px solid #bfbebe',
                                                borderRadius: '4px',
                                                transition: 'all .2s',
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    backgroundColor: '#f2f7ff',
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                            disableRipple
                                            disableFocusRipple
                                            onClick={() => handleChangeAmountNumber(3)}
                                        >
                                            <Typography color={amountNumberState === 3 ? 'white' : 'black'} fontWeight={`bold`}>
                                                3 ตัว
                                            </Typography>
                                        </Button>
                                    </Grid>
                                }
                                {
                                    (checkedEnableNumber.last_two_number) && <Grid item xs={6} md={4}>
                                        <Button fullWidth
                                            sx={{
                                                backgroundColor: amountNumberState === 2 ? 'none' : 'white',
                                                backgroundImage: amountNumberState === 2 ? `url('${ButtonBackground}')` : 'none',
                                                backgroundSize: 'cover',
                                                border: '1px solid #bfbebe',
                                                borderRadius: '4px',
                                                transition: 'all .2s',
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    backgroundColor: '#f2f7ff',
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                            disableRipple
                                            disableFocusRipple
                                            onClick={() => handleChangeAmountNumber(2)}
                                        >
                                            <Typography color={amountNumberState === 2 ? 'white' : 'black'} fontWeight={`bold`}>
                                                2 ตัว
                                            </Typography>
                                        </Button>
                                    </Grid>
                                }
                                <Grid item xs={12} md={4}>
                                    <Button fullWidth
                                        sx={{
                                            backgroundColor: amountNumberState === 4 ? 'none' : 'white',
                                            backgroundImage: amountNumberState === 4 ? `url('${ButtonBackground}')` : 'none',
                                            backgroundSize: 'cover',
                                            border: '1px solid #bfbebe',
                                            borderRadius: '4px',
                                            transition: 'all .2s',
                                            cursor: 'pointer',
                                            '&:hover': {
                                                backgroundColor: '#f2f7ff',
                                                fontWeight: 'bold'
                                            }
                                        }}
                                        disableRipple
                                        disableFocusRipple
                                        onClick={() => handleChangeAmountNumber(4)}
                                    >
                                        <Typography color={amountNumberState === 4 ? 'white' : 'black'} fontWeight={`bold`}>
                                            วิ่ง
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={6} md={3}>
                            <Button fullWidth
                                sx={{
                                    backgroundColor: amountNumberState === 5 ? 'none' : 'white',
                                    backgroundImage: amountNumberState === 5 ? `url('${ButtonBackground}')` : 'none',
                                    backgroundSize: 'cover',
                                    border: '1px solid #bfbebe',
                                    borderRadius: '4px',
                                    transition: 'all .2s',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: '#f2f7ff',
                                        fontWeight: 'bold'
                                    }
                                }}
                                disableRipple
                                disableFocusRipple
                                onClick={() => handleChangeAmountNumber(5)}
                            >
                                <Typography color={amountNumberState === 5 ? 'white' : 'black'} fontWeight={`bold`}>
                                    อื่นๆ
                                </Typography>
                            </Button>
                        </Grid> */}
                        {
                            amountNumberState === 3 && <>
                                <Grid item xs={6} md={4}>
                                    <Button fullWidth
                                        sx={{
                                            background: subTypeInput === 1 ? 'radial-gradient(168% 100% at 50% 0%, rgb(255, 255, 255) 0%, rgb(255, 239, 219) 100%)' : 'white',
                                            backgroundSize: 'cover',
                                            border: '1px solid #bfbebe',
                                            borderColor: subTypeInput === 1 ? 'rgb(255, 125, 5)' : '#bfbebe',
                                            borderRadius: '4px',
                                            transition: 'all .2s',
                                            cursor: 'pointer',
                                            WebkitJustifyContent: 'unset',
                                            justifyContent: 'unset',
                                            '&:hover': {
                                                background: 'radial-gradient(168% 100% at 50% 0%, rgb(255, 255, 255) 0%, rgb(255, 239, 219) 100%)',
                                                fontWeight: 'bold',
                                                borderColor: 'rgb(255, 125, 5)'
                                            },
                                            height: '100%'
                                        }}
                                        disableRipple
                                        disableFocusRipple
                                        onClick={() => handleChangeSubType(1)}
                                    >
                                        <Box display={`flex`} justifyContent={`space-between`} width={`100%`}>
                                            <Typography color={'black'} fontWeight={`bold`} fontSize={11} sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                3 ตัวบน
                                            </Typography>
                                            <Typography color={'rgb(255, 125, 5)'} fontWeight={`bold`} fontSize={11} sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                alignContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                X900
                                            </Typography>
                                        </Box>
                                    </Button>
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    <Button fullWidth
                                        sx={{
                                            background: subTypeInput === 2 ? 'radial-gradient(168% 100% at 50% 0%, rgb(255, 255, 255) 0%, rgb(255, 239, 219) 100%)' : 'white',
                                            backgroundSize: 'cover',
                                            border: '1px solid #bfbebe',
                                            borderColor: subTypeInput === 2 ? 'rgb(255, 125, 5)' : '#bfbebe',
                                            borderRadius: '4px',
                                            transition: 'all .2s',
                                            cursor: 'pointer',
                                            WebkitJustifyContent: 'unset',
                                            justifyContent: 'unset',
                                            '&:hover': {
                                                background: 'radial-gradient(168% 100% at 50% 0%, rgb(255, 255, 255) 0%, rgb(255, 239, 219) 100%)',
                                                fontWeight: 'bold',
                                                borderColor: 'rgb(255, 125, 5)'
                                            },
                                            height: '100%'
                                        }}
                                        disableRipple
                                        disableFocusRipple
                                        onClick={() => handleChangeSubType(2)}
                                    >
                                        <Box display={`flex`} justifyContent={`space-between`} width={`100%`}>
                                            <Typography color={'black'} fontWeight={`bold`} fontSize={11} sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                3 ตัวบนกลับ
                                            </Typography>
                                            <Typography color={'rgb(255, 125, 5)'} fontWeight={`bold`} fontSize={11} sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                alignContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                X900
                                            </Typography>
                                        </Box>
                                    </Button>
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    <Button fullWidth
                                        sx={{
                                            background: subTypeInput === 3 ? 'radial-gradient(168% 100% at 50% 0%, rgb(255, 255, 255) 0%, rgb(255, 239, 219) 100%)' : 'white',
                                            backgroundSize: 'cover',
                                            border: '1px solid #bfbebe',
                                            borderColor: subTypeInput === 3 ? 'rgb(255, 125, 5)' : '#bfbebe',
                                            borderRadius: '4px',
                                            transition: 'all .2s',
                                            cursor: 'pointer',
                                            WebkitJustifyContent: 'unset',
                                            justifyContent: 'unset',
                                            '&:hover': {
                                                background: 'radial-gradient(168% 100% at 50% 0%, rgb(255, 255, 255) 0%, rgb(255, 239, 219) 100%)',
                                                fontWeight: 'bold',
                                                borderColor: 'rgb(255, 125, 5)'
                                            },
                                            height: '100%'
                                        }}
                                        disableRipple
                                        disableFocusRipple
                                        onClick={() => handleChangeSubType(3)}
                                    >
                                        <Box display={`flex`} justifyContent={`space-between`} width={`100%`}>
                                            <Typography color={'black'} fontWeight={`bold`} fontSize={11} sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                3 ตัวบนโต๊ด
                                            </Typography>
                                            <Typography color={'rgb(255, 125, 5)'} fontWeight={`bold`} fontSize={11} sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                alignContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                X150
                                            </Typography>
                                        </Box>
                                    </Button>
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    <Button fullWidth
                                        sx={{
                                            background: subTypeInput === 4 ? 'radial-gradient(168% 100% at 50% 0%, rgb(255, 255, 255) 0%, rgb(255, 239, 219) 100%)' : 'white',
                                            backgroundSize: 'cover',
                                            border: '1px solid #bfbebe',
                                            borderColor: subTypeInput === 4 ? 'rgb(255, 125, 5)' : '#bfbebe',
                                            borderRadius: '4px',
                                            transition: 'all .2s',
                                            cursor: 'pointer',
                                            WebkitJustifyContent: 'unset',
                                            justifyContent: 'unset',
                                            '&:hover': {
                                                background: 'radial-gradient(168% 100% at 50% 0%, rgb(255, 255, 255) 0%, rgb(255, 239, 219) 100%)',
                                                fontWeight: 'bold',
                                                borderColor: 'rgb(255, 125, 5)'
                                            },
                                            height: '100%'
                                        }}
                                        disableRipple
                                        disableFocusRipple
                                        onClick={() => handleChangeSubType(4)}
                                    >
                                        <Box display={`flex`} justifyContent={`space-between`} width={`100%`}>
                                            <Typography color={'black'} fontWeight={`bold`} fontSize={11} sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                3 ตัวบน + โต๊ด
                                            </Typography>
                                            {/* <Typography color={'rgb(255, 125, 5)'} fontWeight={`bold`} fontSize={11} sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                alignContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                X150
                                            </Typography> */}
                                        </Box>
                                    </Button>
                                </Grid>
                            </>
                        }
                        {
                            amountNumberState === 2 && <>
                                <Grid item xs={6} md={4}>
                                    <Button fullWidth
                                        sx={{
                                            background: subTypeInput === 1 ? 'radial-gradient(168% 100% at 50% 0%, rgb(255, 255, 255) 0%, rgb(255, 239, 219) 100%)' : 'white',
                                            backgroundSize: 'cover',
                                            border: '1px solid #bfbebe',
                                            borderColor: subTypeInput === 1 ? 'rgb(255, 125, 5)' : '#bfbebe',
                                            borderRadius: '4px',
                                            transition: 'all .2s',
                                            cursor: 'pointer',
                                            WebkitJustifyContent: 'unset',
                                            justifyContent: 'unset',
                                            '&:hover': {
                                                background: 'radial-gradient(168% 100% at 50% 0%, rgb(255, 255, 255) 0%, rgb(255, 239, 219) 100%)',
                                                fontWeight: 'bold',
                                                borderColor: 'rgb(255, 125, 5)'
                                            },
                                            height: '100%'
                                        }}
                                        disableRipple
                                        disableFocusRipple
                                        onClick={() => handleChangeSubType(1)}
                                    >
                                        <Box display={`flex`} justifyContent={`space-between`} width={`100%`}>
                                            <Typography color={'black'} fontWeight={`bold`} fontSize={11} sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                2 ตัวบน
                                            </Typography>
                                            <Typography color={'rgb(255, 125, 5)'} fontWeight={`bold`} fontSize={11} sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                alignContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                X90
                                            </Typography>
                                        </Box>
                                    </Button>
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    <Button fullWidth
                                        sx={{
                                            background: subTypeInput === 2 ? 'radial-gradient(168% 100% at 50% 0%, rgb(255, 255, 255) 0%, rgb(255, 239, 219) 100%)' : 'white',
                                            backgroundSize: 'cover',
                                            border: '1px solid #bfbebe',
                                            borderColor: subTypeInput === 2 ? 'rgb(255, 125, 5)' : '#bfbebe',
                                            borderRadius: '4px',
                                            transition: 'all .2s',
                                            cursor: 'pointer',
                                            WebkitJustifyContent: 'unset',
                                            justifyContent: 'unset',
                                            '&:hover': {
                                                background: 'radial-gradient(168% 100% at 50% 0%, rgb(255, 255, 255) 0%, rgb(255, 239, 219) 100%)',
                                                fontWeight: 'bold',
                                                borderColor: 'rgb(255, 125, 5)'
                                            },
                                            height: '100%'
                                        }}
                                        disableRipple
                                        disableFocusRipple
                                        onClick={() => handleChangeSubType(2)}
                                    >
                                        <Box display={`flex`} justifyContent={`space-between`} width={`100%`}>
                                            <Typography color={'black'} fontWeight={`bold`} fontSize={11} sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                2 ตัวบนกลับ
                                            </Typography>
                                            <Typography color={'rgb(255, 125, 5)'} fontWeight={`bold`} fontSize={11} sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                alignContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                X90
                                            </Typography>
                                        </Box>
                                    </Button>
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    <Button fullWidth
                                        sx={{
                                            background: subTypeInput === 3 ? 'radial-gradient(168% 100% at 50% 0%, rgb(255, 255, 255) 0%, rgb(255, 239, 219) 100%)' : 'white',
                                            backgroundSize: 'cover',
                                            border: '1px solid #bfbebe',
                                            borderColor: subTypeInput === 3 ? 'rgb(255, 125, 5)' : '#bfbebe',
                                            borderRadius: '4px',
                                            transition: 'all .2s',
                                            cursor: 'pointer',
                                            WebkitJustifyContent: 'unset',
                                            justifyContent: 'unset',
                                            '&:hover': {
                                                background: 'radial-gradient(168% 100% at 50% 0%, rgb(255, 255, 255) 0%, rgb(255, 239, 219) 100%)',
                                                fontWeight: 'bold',
                                                borderColor: 'rgb(255, 125, 5)'
                                            },
                                            height: '100%'
                                        }}
                                        disableRipple
                                        disableFocusRipple
                                        onClick={() => handleChangeSubType(3)}
                                    >
                                        <Box display={`flex`} justifyContent={`space-between`} width={`100%`}>
                                            <Typography color={'black'} fontWeight={`bold`} fontSize={11} sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                2 ตัวล่าง
                                            </Typography>
                                            <Typography color={'rgb(255, 125, 5)'} fontWeight={`bold`} fontSize={11} sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                alignContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                X90
                                            </Typography>
                                        </Box>
                                    </Button>
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    <Button fullWidth
                                        sx={{
                                            background: subTypeInput === 4 ? 'radial-gradient(168% 100% at 50% 0%, rgb(255, 255, 255) 0%, rgb(255, 239, 219) 100%)' : 'white',
                                            backgroundSize: 'cover',
                                            border: '1px solid #bfbebe',
                                            borderColor: subTypeInput === 4 ? 'rgb(255, 125, 5)' : '#bfbebe',
                                            borderRadius: '4px',
                                            transition: 'all .2s',
                                            cursor: 'pointer',
                                            WebkitJustifyContent: 'unset',
                                            justifyContent: 'unset',
                                            '&:hover': {
                                                background: 'radial-gradient(168% 100% at 50% 0%, rgb(255, 255, 255) 0%, rgb(255, 239, 219) 100%)',
                                                fontWeight: 'bold',
                                                borderColor: 'rgb(255, 125, 5)'
                                            },
                                            height: '100%'
                                        }}
                                        disableRipple
                                        disableFocusRipple
                                        onClick={() => handleChangeSubType(4)}
                                    >
                                        <Box display={`flex`} justifyContent={`space-between`} width={`100%`}>
                                            <Typography color={'black'} fontWeight={`bold`} fontSize={11} sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                2 ตัวล่างกลับ
                                            </Typography>
                                            <Typography color={'rgb(255, 125, 5)'} fontWeight={`bold`} fontSize={11} sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                alignContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                X90
                                            </Typography>
                                        </Box>
                                    </Button>
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    <Button fullWidth
                                        sx={{
                                            background: subTypeInput === 5 ? 'radial-gradient(168% 100% at 50% 0%, rgb(255, 255, 255) 0%, rgb(255, 239, 219) 100%)' : 'white',
                                            backgroundSize: 'cover',
                                            border: '1px solid #bfbebe',
                                            borderColor: subTypeInput === 5 ? 'rgb(255, 125, 5)' : '#bfbebe',
                                            borderRadius: '4px',
                                            transition: 'all .2s',
                                            cursor: 'pointer',
                                            WebkitJustifyContent: 'unset',
                                            justifyContent: 'unset',
                                            '&:hover': {
                                                background: 'radial-gradient(168% 100% at 50% 0%, rgb(255, 255, 255) 0%, rgb(255, 239, 219) 100%)',
                                                fontWeight: 'bold',
                                                borderColor: 'rgb(255, 125, 5)'
                                            },
                                            height: '100%'
                                        }}
                                        disableRipple
                                        disableFocusRipple
                                        onClick={() => handleChangeSubType(5)}
                                    >
                                        <Box display={`flex`} justifyContent={`space-between`} width={`100%`}>
                                            <Typography color={'black'} fontWeight={`bold`} fontSize={11} sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                2 ตัวบน/ล่าง
                                            </Typography>
                                            <Typography color={'rgb(255, 125, 5)'} fontWeight={`bold`} fontSize={11} sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                alignContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                X90
                                            </Typography>
                                        </Box>
                                    </Button>
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    <Button fullWidth
                                        sx={{
                                            background: subTypeInput === 6 ? 'radial-gradient(168% 100% at 50% 0%, rgb(255, 255, 255) 0%, rgb(255, 239, 219) 100%)' : 'white',
                                            backgroundSize: 'cover',
                                            border: '1px solid #bfbebe',
                                            borderColor: subTypeInput === 6 ? 'rgb(255, 125, 5)' : '#bfbebe',
                                            borderRadius: '4px',
                                            transition: 'all .2s',
                                            cursor: 'pointer',
                                            WebkitJustifyContent: 'unset',
                                            justifyContent: 'unset',
                                            '&:hover': {
                                                background: 'radial-gradient(168% 100% at 50% 0%, rgb(255, 255, 255) 0%, rgb(255, 239, 219) 100%)',
                                                fontWeight: 'bold',
                                                borderColor: 'rgb(255, 125, 5)'
                                            },
                                            height: '100%'
                                        }}
                                        disableRipple
                                        disableFocusRipple
                                        onClick={() => handleChangeSubType(6)}
                                    >
                                        <Box display={`flex`} justifyContent={`space-between`} width={`100%`}>
                                            <Typography color={'black'} fontWeight={`bold`} fontSize={11} sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                2 ตัวบน/ล่างกลับ
                                            </Typography>
                                            <Typography color={'rgb(255, 125, 5)'} fontWeight={`bold`} fontSize={11} sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                alignContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                X90
                                            </Typography>
                                        </Box>
                                    </Button>
                                </Grid>
                            </>
                        }
                        {
                            amountNumberState === 4 && <>
                                <Grid item xs={6} md={4}>
                                    <Button fullWidth
                                        sx={{
                                            background: subTypeInput === 1 ? 'radial-gradient(168% 100% at 50% 0%, rgb(255, 255, 255) 0%, rgb(255, 239, 219) 100%)' : 'white',
                                            backgroundSize: 'cover',
                                            border: '1px solid #bfbebe',
                                            borderColor: subTypeInput === 1 ? 'rgb(255, 125, 5)' : '#bfbebe',
                                            borderRadius: '4px',
                                            transition: 'all .2s',
                                            cursor: 'pointer',
                                            WebkitJustifyContent: 'unset',
                                            justifyContent: 'unset',
                                            '&:hover': {
                                                background: 'radial-gradient(168% 100% at 50% 0%, rgb(255, 255, 255) 0%, rgb(255, 239, 219) 100%)',
                                                fontWeight: 'bold',
                                                borderColor: 'rgb(255, 125, 5)'
                                            },
                                            height: '100%'
                                        }}
                                        disableRipple
                                        disableFocusRipple
                                        onClick={() => handleChangeSubType(1)}
                                    >
                                        <Box display={`flex`} justifyContent={`space-between`} width={`100%`}>
                                            <Typography color={'black'} fontWeight={`bold`} fontSize={11} sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                วิ่งบน
                                            </Typography>
                                            <Typography color={'rgb(255, 125, 5)'} fontWeight={`bold`} fontSize={11} sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                alignContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                X3.2
                                            </Typography>
                                        </Box>
                                    </Button>
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    <Button fullWidth
                                        sx={{
                                            background: subTypeInput === 2 ? 'radial-gradient(168% 100% at 50% 0%, rgb(255, 255, 255) 0%, rgb(255, 239, 219) 100%)' : 'white',
                                            backgroundSize: 'cover',
                                            border: '1px solid #bfbebe',
                                            borderColor: subTypeInput === 2 ? 'rgb(255, 125, 5)' : '#bfbebe',
                                            borderRadius: '4px',
                                            transition: 'all .2s',
                                            cursor: 'pointer',
                                            WebkitJustifyContent: 'unset',
                                            justifyContent: 'unset',
                                            '&:hover': {
                                                background: 'radial-gradient(168% 100% at 50% 0%, rgb(255, 255, 255) 0%, rgb(255, 239, 219) 100%)',
                                                fontWeight: 'bold',
                                                borderColor: 'rgb(255, 125, 5)'
                                            },
                                            height: '100%'
                                        }}
                                        disableRipple
                                        disableFocusRipple
                                        onClick={() => handleChangeSubType(2)}
                                    >
                                        <Box display={`flex`} justifyContent={`space-between`} width={`100%`}>
                                            <Typography color={'black'} fontWeight={`bold`} fontSize={11} sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                วิ่งล่าง
                                            </Typography>
                                            <Typography color={'rgb(255, 125, 5)'} fontWeight={`bold`} fontSize={11} sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                alignContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                X4.2
                                            </Typography>
                                        </Box>
                                    </Button>
                                </Grid>
                            </>
                        }
                        <Grid item xs={12} mt={1}>
                            <Divider />
                        </Grid>
                    </Grid>
                    {
                        typeInput === 1 ? amountNumberState >= 2 && amountNumberState <= 4 && <PadNumber
                            handlePushNumber={handleAddSingleNumber}
                            amountNumber={amountNumberState > 3 ? (amountNumberState === 4 ? 1 : 3) : amountNumberState}
                            handleAddMultiNumber={handleAddMultiNumber}
                            subType={subTypeInput}
                            deleteLastAdded={deleteLastAdded}
                        /> : typeInput === 2 ? <NumberSet
                            handlePushNumber={handleAddSingleNumber}
                            amountNumber={amountNumberState > 3 ? (amountNumberState === 4 ? 1 : 3) : amountNumberState}
                            handleAddMultiNumber={handleAddMultiNumber}
                            subType={subTypeInput}
                            deleteLastAdded={deleteLastAdded}
                        /> : null
                    }
                </Box>
                {
                    isDesktop ? <Box sx={{ height: `100%`, maxHeight: `1px  `, mb: 18 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={5}>
                                <Button fullWidth
                                    sx={{
                                        backgroundImage: `url('${ButtonLightBackground}')`,
                                        backgroundSize: 'cover',
                                        border: '1px solid #bfbebe',
                                        borderRadius: '4px',
                                        transition: 'all .2s',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            backgroundColor: '#f2f7ff',
                                            fontWeight: 'bold'
                                        },
                                        height: 45
                                    }}
                                    disableRipple
                                    disableFocusRipple
                                    onClick={() => navigate('/')}
                                >
                                    <Typography color={'black'}>
                                        กลับหน้าหลัก
                                    </Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={7}>
                                <Button fullWidth
                                    sx={{
                                        backgroundColor: 'none',
                                        backgroundImage: `url('${ButtonBackground}')`,
                                        backgroundSize: 'cover',
                                        border: '1px solid #bfbebe',
                                        borderRadius: '4px',
                                        transition: 'all .2s',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            backgroundColor: '#f2f7ff',
                                            fontWeight: 'bold',
                                        },
                                        height: 45
                                    }}
                                    disableRipple
                                    disableFocusRipple
                                    onClick={() => setOpenTabInputPrice(true)}
                                >
                                    <Typography color={'white'} fontWeight={`bold`}>
                                        ใส่ราคา
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Box> : <Box sx={
                        {
                            display: {
                                xs: 'block',
                                sm: 'block',
                                md: 'block',
                                lg: 'block'
                            }
                        }
                    }>
                        <Box sx={
                            {
                                position: 'fixed',
                                left: 0,
                                bottom: 0,
                                zIndex: 4,
                                width: `100%`,
                            }
                        }>
                            <Container sx={{ py: 1, px: 2, backgroundColor: "white", boxShadow: "0 -4px 6px -2px rgba(0, 0, 0, 0.2)" }}>
                                <Grid container spacing={1}>
                                    <Grid item xs={5}>
                                        <Button fullWidth
                                            sx={{
                                                backgroundImage: `url('${ButtonLightBackground}')`,
                                                backgroundSize: 'cover',
                                                border: '1px solid #bfbebe',
                                                borderRadius: '4px',
                                                transition: 'all .2s',
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    backgroundColor: '#f2f7ff',
                                                    fontWeight: 'bold'
                                                },
                                                height: 45
                                            }}
                                            disableRipple
                                            disableFocusRipple
                                            onClick={() => navigate('/')}
                                        >
                                            <Typography color={'black'}>
                                                กลับหน้าหลัก
                                            </Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Button fullWidth
                                            sx={{
                                                backgroundColor: 'none',
                                                backgroundImage: `url('${ButtonBackground}')`,
                                                backgroundSize: 'cover',
                                                border: '1px solid #bfbebe',
                                                borderRadius: '4px',
                                                transition: 'all .2s',
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    backgroundColor: '#f2f7ff',
                                                    fontWeight: 'bold',
                                                },
                                                height: 45
                                            }}
                                            disableRipple
                                            disableFocusRipple
                                            onClick={() => setOpenTabInputPrice(true)}
                                        >
                                            <Typography color={'white'} fontWeight={`bold`}>
                                                ใส่ราคา
                                            </Typography>
                                        </Button>
                                    </Grid>
                                </Grid> 
                            </Container>
                        </Box>
                    </Box>
                }
            </Box>
        </>
    )
}

export default InputNumber;