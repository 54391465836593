import { Box, Container, Typography } from "@mui/material";
import Payment from './../../assets/images/icon-payment.png';

const Footer = () => {
    return (
        <>
            <Box width={`100%`} sx={{
                backgroundColor: '#cf1f2d',
                mt: 5,
                py: 5
            }}>
                <Container>
                    <Typography fontSize={22} fontWeight={`bold`} align="center">
                        เว็บแทงหวย Lotto69 บริการหวยครบวงจร แทงง่ายเล่นได้ทั้งวัน
                    </Typography>
                    <Typography align="center" my={2}>
                        เว็บแทงหวยออนไลน์ Lotto69 ที่มาพร้อมกับบริการหวยครบวงจร ให้คุณได้สัมผัสประสบการณ์แทงหวยที่ง่ายดายและสะดวกสบาย ลุ้นรางวัลใหญ่ได้ตลอด 24 ชั่วโมง พร้อมสร้างรายได้แบบไม่มีขีดจำกัด Lotto69 การันตีความปลอดภัยและความน่าเชื่อถือ พร้อมเปิดให้แทงหวยได้ทุกประเภทไม่ว่าจะเป็น หวยลาว หวยใต้ดิน หวยรัฐบาล หวยยี่กี หวยฮานอย หรือหวยจากต่างประเทศ ก็มีให้เลือกอย่างครบครัน
                    </Typography>
                    <Typography fontSize={22} fontWeight={`bold`} align="center">
                        PAYMENTS ACCEPTED
                    </Typography>
                    <Box width={`100%`} display={`flex`} justifyContent={`center`} mt={1}>
                        <img src={Payment} alt={`payment`} width={`100%`} style={{ maxWidth: 500 }} />
                    </Box>
                </Container>
            </Box>
            <Box width={`100%`} sx={{
                backgroundColor: '#9c1721',
                py: 1
            }}>
                <Container>
                    <Typography align="center" color={`rgba(255, 255, 255, .7)`} fontWeight={`bold`}>
                        Copyright &copy; 2024 LOTTO69. All Rights Reserved.
                    </Typography>
                </Container>
            </Box>
        </>
    )
}

export default Footer;