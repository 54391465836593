import { ChangeEvent, FormEvent, useRef, useState } from "react";
import Cookies from "universal-cookie";
import { Box, Typography, FormControl, OutlinedInput, Button, CircularProgress } from "@mui/material";
import Swal from "sweetalert2";
import { ApiService } from "../../services/api.service";
import { useNavigate } from "react-router-dom";

const SubWithdraw = () => {
    const navigate = useNavigate();
    const cookies = new Cookies(null, { path: '/' });
    const [loading, setLoading] = useState<boolean>(false);
    const amountRef = useRef<HTMLInputElement>(null);

    const isOnlyDigits = (str: string) => {
        const pattern = /^\d+$/;
        return pattern.test(str);
    }

    const submit_deposit = async (e: FormEvent) => {
        e.preventDefault();
        try {
            if(!amountRef.current || amountRef.current.value.trim() === '')
            {
                return;
            }

            const amount = amountRef.current.value.trim();
            if(!isOnlyDigits(amount))
            {
                return Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: 'กรุณากรอกจำนวนที่ต้องการถอนเป็นตัวเลขเท่านั้น (ไม่ต้องมีทศนิยม)'
                });
            }
            else if(parseFloat(amount) <= 0)
            {
                return Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: 'จำนวนที่ต้องการถอน ต้องมากกว่า 0 บาทค่ะ'
                });
            }

            setLoading(true);
            const service = new ApiService();
            const getEndpoint = await service.getEndpointPay();

            if(getEndpoint.data.status_code !== 200)
            {
                return Swal.fire({
                    icon: 'success',
                    title: 'แจ้งเตือน',
                    text: `สร้างคำร้องถอนเงินไม่สำเร็จ เนื่องจากไม่สามารถรับ Endpoint ได้กรุณาติดต่อแอดมินค่ะ`
                }).then(() => {
                    navigate('/');
                });
            }

            const endpointPath = getEndpoint.data.data.path;
            const endpoint = getEndpoint.data.data.withdraw_endpoint;
            const request = await service.requestPayout(cookies.get('access_token'), amount, endpointPath, endpoint);
            if(request.data.status_code === 200)
            {
                Swal.fire({
                    icon: 'success',
                    title: 'แจ้งเตือน',
                    text: `สร้างคำร้องถอนเงินจำนวน ${parseFloat(amount).toLocaleString('en-US', {maximumFractionDigits: 0})} เรียบร้อยแล้วค่ะ`
                }).then(() => {
                    navigate('/');
                });
            }
            else
            {
                Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    html: `สร้างคำร้องถอนเงินไม่สำเร็จ<br/>(ข้อผิดพลาด: ${request.data.message || "ไม่ทราบข้อผิดพลาด"})`
                });
            }

            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if(!event.target)
        {
            return;
        }

        const newValue = event.target.value;
        const inputElement = document.getElementById('deposit_amount') as HTMLInputElement;

        if (/^\d*$/.test(newValue))
        {
            if(inputElement)
            {
                inputElement.value = newValue;
            }
        }
        else
        {
            const numericValue = newValue.replace(/\D/g, '');
            if(inputElement)
            {
                inputElement.value = numericValue;
            }
        }
    };
    
    return (
        <form name="user_deposit" id="user_deposit" onSubmit={submit_deposit}>
            <Box bgcolor={'white'} sx={{ borderRadius: '0px 0px 15px 15px' }}>
                <Box p={2}>
                    <Typography align="center" fontSize={20} color={'#313131'}>
                        ถอนเงิน
                    </Typography>
                    <Typography align="center" color={'#9c9c9c'}>
                        กรอกจำนวนเงินที่ต้องการถอน
                    </Typography>
                    <FormControl fullWidth sx={{ mt: 1 }}>
                        <OutlinedInput
                            id='deposit_amount'
                            name='deposit_amount'
                            type={`number`}
                            size='small'
                            onChange={handleChange}
                            fullWidth
                            autoComplete="deposit_amount"
                            placeholder="จำนวนเงินที่ต้องการถอน (จำนวนเงินบาท)"
                            inputRef={amountRef}
                            sx={
                                {
                                    backgroundColor: 'white'
                                }
                            }
                        />
                    </FormControl>
                    <Button type="submit" fullWidth sx={
                        {
                            mt: 1,
                            py: 1,
                            backgroundColor: '#ec2230',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#c61d29',
                                color: 'white',
                            }
                        }
                    } disabled={loading}>
                        <Typography fontSize={16} fontWeight={500}>
                            {
                                loading ? <CircularProgress size={20} sx={
                                    {
                                        color: 'white'
                                    }
                                } /> : "ยืนยันการถอนเงิน"
                            }
                        </Typography>
                    </Button>
                </Box>
            </Box>
        </form>
    )
}

export default SubWithdraw;