import { useEffect, useState } from "react";
import { ApiService } from "../../services/api.service";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import DiceSpin from "../Loading/DiceSpin";
import moment from "moment";

import { Box, Button, Divider, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { memberBillsType, memberDataType } from "../../types";
import PopupMemberLottoBill from "./PopupMemberLottoBill";

const MemberLottoBill = () => {
    const cookies = new Cookies(null, { path: '/' });
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [bills, setBills] = useState<memberBillsType[]>([]);

    const [openPopupBill, setOpenPopupBill] = useState<boolean>(false);
    const [openBillId, setOpenBillId] = useState<number>(0);
    const [openBillUuid, setOpenBillUuid] = useState<string>('');

    useEffect(() => {
        getBills();
    }, []);

    const getBills = async () => {
        if(loading)
        {
            return;
        }
        else if(!cookies.get('access_token'))
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: `เกิดข้อผิดพลาด คุณยังไม่ได้เข้าสู่ระบบค่ะ`
            }).then(() => {
                navigate('/');
            });
        }

        setLoading(true);
        try {
            const service = new ApiService();
            const getBills = await service.getMemberBills(cookies.get('access_token'));

            if(getBills.data.status_code !== 200)
            {
                return Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: `เกิดข้อผิดพลาด - ${getBills.data.status_code} ${getBills.data.message}`
                }).then(() => {
                    navigate('/');
                });
            }

            setBills(getBills.data.bills);
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }

    return (
        <>
            <PopupMemberLottoBill isOpenState={openPopupBill} setIsOpenState={setOpenPopupBill} billId={openBillId} billUuid={openBillUuid} />
            <Box p={2} sx={{ color: 'black' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography fontSize={20}>
                            โพยหวย
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        {
                            loading ? <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                <DiceSpin />
                            </Box> : <Grid container spacing={2}>
                                {
                                    bills.length > 0 ? bills.map((element: memberBillsType, index: number) => {
                                        return (
                                            <Grid item xs={12} md={3} key={`bill-${index}`}>
                                                <Box sx={{
                                                    backgroundColor: 'white',
                                                    color: 'black',
                                                    boxShadow: "0 4px 4px 2px rgba(0, 0, 0, 0.15)",
                                                    borderRadius: 2
                                                }}>
                                                    <Box sx={{ p: 2 }}>
                                                        <Typography display={`flex`} justifyContent={`center`}>
                                                            { element.lotto_name } งวด { element.period_name }
                                                        </Typography>
                                                        <Typography display={`flex`} justifyContent={`center`} fontSize={14} color={'#9c9c9c'}>
                                                            {
                                                                element.uuid
                                                            }
                                                        </Typography>
                                                        <Divider sx={{ mb: 1 }} />
                                                        <Box display={`flex`} justifyContent={`space-between`}>
                                                            <Typography display={`flex`} alignContent={`center`} alignItems={`center`}>
                                                                จำนวนทั้งหมด:
                                                            </Typography>
                                                            <Typography display={`flex`} alignContent={`center`} alignItems={`center`}>
                                                                {
                                                                    parseFloat(String(JSON.parse(element.bills).length)).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0})
                                                                } รายการ
                                                            </Typography>
                                                        </Box>
                                                        <Box display={`flex`} justifyContent={`space-between`}>
                                                            <Typography display={`flex`} alignContent={`center`} alignItems={`center`}>
                                                                ยอดทั้งหมด:
                                                            </Typography>
                                                            <Typography display={`flex`} alignContent={`center`} alignItems={`center`}>
                                                                ฿{
                                                                    parseFloat(String(JSON.parse(element.bills).reduce((total: number, item: {
                                                                        number: string;
                                                                        amountNumberState: number;
                                                                        price: number;
                                                                        pay: number;
                                                                    }) => total + item.price, 0))).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0})
                                                                }
                                                            </Typography>
                                                        </Box>
                                                        <Box display={`flex`} justifyContent={`space-between`}>
                                                            <Typography display={`flex`} alignContent={`center`} alignItems={`center`}>
                                                                ผลโพย:
                                                            </Typography>
                                                            <Typography display={`flex`} alignContent={`center`} alignItems={`center`} sx={{
                                                                backgroundColor: element.results === 0 ? "yellow" : (element.results === 1 ? "red" : "green"),
                                                                px: 1,
                                                                borderRadius: 1,
                                                                color: 'white'
                                                            }}>
                                                                {
                                                                    element.results === 0 ? "รอผลรางวัล" : (
                                                                        element.results === 1 ? "ไม่ถูกรางวัล" : "ถูกรางวัล"
                                                                    )
                                                                }
                                                            </Typography>
                                                        </Box>
                                                        <Box display={`flex`} justifyContent={`space-between`}>
                                                            <Typography display={`flex`} alignContent={`center`} alignItems={`center`}>
                                                                ผลแพ้ชนะ:
                                                            </Typography>
                                                            <Typography display={`flex`} alignContent={`center`} alignItems={`center`}>
                                                                {/* {
                                                                    (element.after_credit - element.before_credit) < 0 ? " - " : " + "
                                                                } */}
                                                                ฿{
                                                                    parseFloat(String(element.after_credit - element.before_credit)).toLocaleString('en-US', {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2
                                                                    })
                                                                }
                                                            </Typography>
                                                        </Box>
                                                        <Box display={`flex`} justifyContent={`space-between`}>
                                                            <Typography display={`flex`} alignContent={`center`} alignItems={`center`}>
                                                                วันเวลาที่ซื้อ:
                                                            </Typography>
                                                            <Typography display={`flex`} alignContent={`center`} alignItems={`center`}>
                                                                {
                                                                    moment(element.createdAt).format("D/M/YYYY H:m:s")
                                                                }
                                                            </Typography>
                                                        </Box>
                                                        <Divider sx={{ mt: 1 }} />
                                                        <Button fullWidth sx={
                                                            {
                                                                mt: 1,
                                                                backgroundColor: '#9c9c9c',
                                                                color: 'white',
                                                                '&:hover': {
                                                                    backgroundColor: '#696969',
                                                                    color: 'white',
                                                                }
                                                            }
                                                        } onClick={() => {
                                                            setOpenBillId(element.id);
                                                            setOpenBillUuid(element.uuid);
                                                            setOpenPopupBill(true);
                                                        }}>
                                                            <Typography fontSize={16} fontWeight={500}>
                                                            ดูรายละเอียดโพยหวยนี้
                                                            </Typography>
                                                        </Button>
                                                        {/* <Link to={`/bill/${element.uuid}`}>
                                                            <Typography display={`flex`} justifyContent={'center'} alignContent={`center`} alignItems={`center`}>
                                                                ดูรายละเอียดโพยหวยนี้
                                                            </Typography>
                                                        </Link> */}
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        )
                                    }) : <Grid item xs={12}>
                                        <Typography display={`flex`} justifyContent={`center`}>
                                            คุณไม่มีโพยหวยค่ะ
                                        </Typography>
                                    </Grid>
                                }
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default MemberLottoBill;