import { useEffect, useState } from "react";
import { allResultsType } from "../../types";
import { Divider, Grid, Typography } from "@mui/material"
import SingleResult from "./SingleResult"

import ThaiFlag from './../../assets/images/flag/Flag_of_Thailand.svg';
import BAACFlag from './../../assets/images/flag/Flag_of_TKS.jpg';
import GSBFlag from './../../assets/images/flag/FlagOfAomsin.jpg';
import MalayFlag from './../../assets/images/flag/Flag_of_Malaya.png';
import LaoFlag from './../../assets/images/flag/Flag_of_Laos.svg';
import VietnamFlag from './../../assets/images/flag/Flag_of_Vietnam.png';
import AmericaFlag from './../../assets/images/flag/Flag_of_America.png';
import EgyptFlag from './../../assets/images/flag/Flag_of_Egypt.svg';
import JapanFlag from './../../assets/images/flag/Flag_of_Japan.svg';
import ChainaFlag from './../../assets/images/flag/Flag_of_China.png';
import HongkongFlag from './../../assets/images/flag/Flag_of_Hongkong.png';
import KoreanFlag from './../../assets/images/flag/Flag_of_South_Korea.svg';
import SingaporeFlag from './../../assets/images/flag/Flag_of_Singapore.png';
import GermanyFlag from './../../assets/images/flag/Flag_of_Germany.png';
import IndiaFlag from './../../assets/images/flag/Flag_of_India.svg'
import RussiaFlag from './../../assets/images/flag/Flag_of_Russia.png'
import EnglishFlag from './../../assets/images/flag/Flag_of_English.svg'
import TaiwanFlag from './../../assets/images/flag/Flag_of_the_Republic_of_China.svg'
import { ApiService } from "../../services/api.service";
import Swal from "sweetalert2";

const ResultsPage = () => {
    const [allResults, setAllResults] = useState<allResultsType[] | null>(null);

    useEffect(() => {
        loadResultsData();
    }, []);

    const loadResultsData = async () => {
        try {
            const service = new ApiService();
            const getResults = await service.getLottoAllResults();

            if(getResults.data.status_code === 200)
            {
                setAllResults(getResults.data.results);
            }
            else
            {
                Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: `เกิดข้อผิดพลาดในการโหลดข้อมูล กรุณาลองใหม่อีกครั้ง (Err: ${getResults.data.status_code - getResults.data.message})`
                });
            }
        } catch (err) {
            console.log(err);
        }
    }

    return (
        allResults ? <>
            {/* RESULTS */}
            <Grid container spacing={2} mt={3}>
                <Grid item xs={12}>
                    <Typography color={'black'} fontSize={26} lineHeight={0}>
                        หวย
                    </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                    <SingleResult
                        img={ThaiFlag}
                        name={'หวยรัฐบาลไทย'}
                        date={allResults[allResults.findIndex(element => element.id === 21)].period_name ? allResults[allResults.findIndex(element => element.id === 21)].period_name : '?'}
                        time={null}
                        firstResult={allResults[allResults.findIndex(element => element.id === 21)].six_number ? allResults[allResults.findIndex(element => element.id === 21)].six_number : 'XXXXXX'}
                        threePrefix={allResults[allResults.findIndex(element => element.id === 21)].first_three_number ? allResults[allResults.findIndex(element => element.id === 21)].first_three_number : 'XXX'}
                        threeBottom={allResults[allResults.findIndex(element => element.id === 21)].last_three_number ? allResults[allResults.findIndex(element => element.id === 21)].last_three_number : 'XXX'}
                        twoBottom={allResults[allResults.findIndex(element => element.id === 21)].last_two_number ? allResults[allResults.findIndex(element => element.id === 21)].last_two_number : 'XX'}
                        lastGrid={4}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <SingleResult
                        img={BAACFlag}
                        name={'หวย ธกส.'}
                        date={allResults[allResults.findIndex(element => element.id === 25)].period_name ? allResults[allResults.findIndex(element => element.id === 25)].period_name : '?'}
                        time={null}
                        firstResult={allResults[allResults.findIndex(element => element.id === 25)].six_number}
                        threePrefix={allResults[allResults.findIndex(element => element.id === 25)].first_three_number}
                        threeBottom={null}
                        twoBottom={allResults[allResults.findIndex(element => element.id === 25)].last_two_number}
                        lastGrid={6}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <SingleResult
                        img={GSBFlag}
                        name={'หวยออมสิน'}
                        date={allResults[allResults.findIndex(element => element.id === 26)].period_name ? allResults[allResults.findIndex(element => element.id === 26)].period_name : '?'}
                        time={null}
                        firstResult={allResults[allResults.findIndex(element => element.id === 26)].six_number}
                        threePrefix={allResults[allResults.findIndex(element => element.id === 26)].first_three_number}
                        threeBottom={null}
                        twoBottom={allResults[allResults.findIndex(element => element.id === 26)].last_two_number}
                        lastGrid={6}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{
                        my: 2
                    }} />
                </Grid>
                <Grid item xs={12}>
                    <Typography color={'black'} fontSize={26} lineHeight={0}>
                        หวยหุ้น
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <SingleResult
                        img={MalayFlag}
                        name={'หวยมาเลย์'}
                        date={allResults[allResults.findIndex(element => element.id === 27)].period_name ? allResults[allResults.findIndex(element => element.id === 27)].period_name : '?'}
                        time={null}
                        firstResult={null}
                        threePrefix={allResults[allResults.findIndex(element => element.id === 27)].first_three_number}
                        threeBottom={null}
                        twoBottom={allResults[allResults.findIndex(element => element.id === 27)].last_two_number}
                        lastGrid={6}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <SingleResult
                        img={LaoFlag}
                        name={'หวยลาว'}
                        date={allResults[allResults.findIndex(element => element.id === 28)].period_name ? allResults[allResults.findIndex(element => element.id === 28)].period_name : '?'}
                        time={null}
                        firstResult={null}
                        threePrefix={allResults[allResults.findIndex(element => element.id === 28)].first_three_number}
                        threeBottom={null}
                        twoBottom={allResults[allResults.findIndex(element => element.id === 28)].last_two_number}
                        lastGrid={6}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <SingleResult
                        img={VietnamFlag}
                        name={'หวยฮานอย'}
                        date={allResults[allResults.findIndex(element => element.id === 29)].period_name ? allResults[allResults.findIndex(element => element.id === 29)].period_name : '?'}
                        time={null}
                        firstResult={null}
                        threePrefix={allResults[allResults.findIndex(element => element.id === 29)].first_three_number}
                        threeBottom={null}
                        twoBottom={allResults[allResults.findIndex(element => element.id === 29)].last_two_number}
                        lastGrid={6}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <SingleResult
                        img={VietnamFlag}
                        name={'หวยฮานอยพิเศษ'}
                        date={allResults[allResults.findIndex(element => element.id === 30)].period_name ? allResults[allResults.findIndex(element => element.id === 30)].period_name : '?'}
                        time={null}
                        firstResult={null}
                        threePrefix={allResults[allResults.findIndex(element => element.id === 30)].first_three_number}
                        threeBottom={null}
                        twoBottom={allResults[allResults.findIndex(element => element.id === 30)].last_two_number}
                        lastGrid={6}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <SingleResult
                        img={AmericaFlag}
                        name={'หุ้นดาวโจนส์'}
                        date={allResults[allResults.findIndex(element => element.id === 31)].period_name ? allResults[allResults.findIndex(element => element.id === 31)].period_name : '?'}
                        time={null}
                        firstResult={null}
                        threePrefix={allResults[allResults.findIndex(element => element.id === 31)].first_three_number}
                        threeBottom={null}
                        twoBottom={allResults[allResults.findIndex(element => element.id === 31)].last_two_number}
                        lastGrid={6}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <SingleResult
                        img={EgyptFlag}
                        name={'หุ้นอียิป'}
                        date={allResults[allResults.findIndex(element => element.id === 32)].period_name ? allResults[allResults.findIndex(element => element.id === 32)].period_name : '?'}
                        time={null}
                        firstResult={null}
                        threePrefix={allResults[allResults.findIndex(element => element.id === 32)].first_three_number}
                        threeBottom={null}
                        twoBottom={allResults[allResults.findIndex(element => element.id === 32)].last_two_number}
                        lastGrid={6}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <SingleResult
                        img={JapanFlag}
                        name={'หุ้นเคอิ'}
                        date={allResults[allResults.findIndex(element => element.id === 33)].period_name ? allResults[allResults.findIndex(element => element.id === 33)].period_name : '?'}
                        time={null}
                        firstResult={null}
                        threePrefix={allResults[allResults.findIndex(element => element.id === 33)].first_three_number}
                        threeBottom={null}
                        twoBottom={allResults[allResults.findIndex(element => element.id === 33)].last_two_number}
                        lastGrid={6}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <SingleResult
                        img={JapanFlag}
                        name={'หุ้นเคอิ'}
                        date={allResults[allResults.findIndex(element => element.id === 33)].period_name ? allResults[allResults.findIndex(element => element.id === 33)].period_name : '?'}
                        time={null}
                        firstResult={null}
                        threePrefix={allResults[allResults.findIndex(element => element.id === 33)].first_three_number}
                        threeBottom={null}
                        twoBottom={allResults[allResults.findIndex(element => element.id === 33)].last_two_number}
                        lastGrid={6}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <SingleResult
                        img={ChainaFlag}
                        name={'หุ้นจีน'}
                        date={allResults[allResults.findIndex(element => element.id === 34)].period_name ? allResults[allResults.findIndex(element => element.id === 34)].period_name : '?'}
                        time={null}
                        firstResult={null}
                        threePrefix={allResults[allResults.findIndex(element => element.id === 34)].first_three_number}
                        threeBottom={null}
                        twoBottom={allResults[allResults.findIndex(element => element.id === 34)].last_two_number}
                        lastGrid={6}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <SingleResult
                        img={ChainaFlag}
                        name={'หุ้นจีน'}
                        date={allResults[allResults.findIndex(element => element.id === 34)].period_name ? allResults[allResults.findIndex(element => element.id === 34)].period_name : '?'}
                        time={null}
                        firstResult={null}
                        threePrefix={allResults[allResults.findIndex(element => element.id === 34)].first_three_number}
                        threeBottom={null}
                        twoBottom={allResults[allResults.findIndex(element => element.id === 34)].last_two_number}
                        lastGrid={6}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <SingleResult
                        img={HongkongFlag}
                        name={'หุ้นฮั่งเส็ง'}
                        date={allResults[allResults.findIndex(element => element.id === 35)].period_name ? allResults[allResults.findIndex(element => element.id === 35)].period_name : '?'}
                        time={null}
                        firstResult={null}
                        threePrefix={allResults[allResults.findIndex(element => element.id === 35)].first_three_number}
                        threeBottom={null}
                        twoBottom={allResults[allResults.findIndex(element => element.id === 35)].last_two_number}
                        lastGrid={6}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <SingleResult
                        img={HongkongFlag}
                        name={'หุ้นฮั่งเส็ง'}
                        date={allResults[allResults.findIndex(element => element.id === 35)].period_name ? allResults[allResults.findIndex(element => element.id === 35)].period_name : '?'}
                        time={null}
                        firstResult={null}
                        threePrefix={allResults[allResults.findIndex(element => element.id === 35)].first_three_number}
                        threeBottom={null}
                        twoBottom={allResults[allResults.findIndex(element => element.id === 35)].last_two_number}
                        lastGrid={6}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <SingleResult
                        img={KoreanFlag}
                        name={'หุ้นเกาหลี'}
                        date={allResults[allResults.findIndex(element => element.id === 36)].period_name ? allResults[allResults.findIndex(element => element.id === 36)].period_name : '?'}
                        time={null}
                        firstResult={null}
                        threePrefix={allResults[allResults.findIndex(element => element.id === 36)].first_three_number}
                        threeBottom={null}
                        twoBottom={allResults[allResults.findIndex(element => element.id === 36)].last_two_number}
                        lastGrid={6}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <SingleResult
                        img={SingaporeFlag}
                        name={'หุ้นสิงคโปร์'}
                        date={allResults[allResults.findIndex(element => element.id === 37)].period_name ? allResults[allResults.findIndex(element => element.id === 37)].period_name : '?'}
                        time={null}
                        firstResult={null}
                        threePrefix={allResults[allResults.findIndex(element => element.id === 37)].first_three_number}
                        threeBottom={null}
                        twoBottom={allResults[allResults.findIndex(element => element.id === 37)].last_two_number}
                        lastGrid={6}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <SingleResult
                        img={GermanyFlag}
                        name={'หุ้นเยอรมัน'}
                        date={allResults[allResults.findIndex(element => element.id === 38)].period_name ? allResults[allResults.findIndex(element => element.id === 38)].period_name : '?'}
                        time={null}
                        firstResult={null}
                        threePrefix={allResults[allResults.findIndex(element => element.id === 38)].first_three_number}
                        threeBottom={null}
                        twoBottom={allResults[allResults.findIndex(element => element.id === 38)].last_two_number}
                        lastGrid={6}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <SingleResult
                        img={ThaiFlag}
                        name={'หุ้นไทย'}
                        date={allResults[allResults.findIndex(element => element.id === 39)].period_name ? allResults[allResults.findIndex(element => element.id === 39)].period_name : '?'}
                        time={null}
                        firstResult={null}
                        threePrefix={allResults[allResults.findIndex(element => element.id === 39)].first_three_number}
                        threeBottom={null}
                        twoBottom={allResults[allResults.findIndex(element => element.id === 39)].last_two_number}
                        lastGrid={6}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <SingleResult
                        img={ThaiFlag}
                        name={'หุ้นไทย'}
                        date={allResults[allResults.findIndex(element => element.id === 39)].period_name ? allResults[allResults.findIndex(element => element.id === 39)].period_name : '?'}
                        time={null}
                        firstResult={null}
                        threePrefix={allResults[allResults.findIndex(element => element.id === 39)].first_three_number}
                        threeBottom={null}
                        twoBottom={allResults[allResults.findIndex(element => element.id === 39)].last_two_number}
                        lastGrid={6}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <SingleResult
                        img={IndiaFlag}
                        name={'หุ้นอินเดีย'}
                        date={allResults[allResults.findIndex(element => element.id === 40)].period_name ? allResults[allResults.findIndex(element => element.id === 40)].period_name : '?'}
                        time={null}
                        firstResult={null}
                        threePrefix={allResults[allResults.findIndex(element => element.id === 40)].first_three_number}
                        threeBottom={null}
                        twoBottom={allResults[allResults.findIndex(element => element.id === 40)].last_two_number}
                        lastGrid={6}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <SingleResult
                        img={RussiaFlag}
                        name={'หุ้นรัสเซีย'}
                        date={allResults[allResults.findIndex(element => element.id === 41)].period_name ? allResults[allResults.findIndex(element => element.id === 41)].period_name : '?'}
                        time={null}
                        firstResult={null}
                        threePrefix={allResults[allResults.findIndex(element => element.id === 41)].first_three_number}
                        threeBottom={null}
                        twoBottom={allResults[allResults.findIndex(element => element.id === 41)].last_two_number}
                        lastGrid={6}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <SingleResult
                        img={EnglishFlag}
                        name={'หุ้นอังกฤษ'}
                        date={allResults[allResults.findIndex(element => element.id === 42)].period_name ? allResults[allResults.findIndex(element => element.id === 42)].period_name : '?'}
                        time={null}
                        firstResult={null}
                        threePrefix={allResults[allResults.findIndex(element => element.id === 42)].first_three_number}
                        threeBottom={null}
                        twoBottom={allResults[allResults.findIndex(element => element.id === 42)].last_two_number}
                        lastGrid={6}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <SingleResult
                        img={TaiwanFlag}
                        name={'หุ้นไต้หวัน'}
                        date={allResults[allResults.findIndex(element => element.id === 43)].period_name ? allResults[allResults.findIndex(element => element.id === 43)].period_name : '?'}
                        time={null}
                        firstResult={null}
                        threePrefix={allResults[allResults.findIndex(element => element.id === 43)].first_three_number}
                        threeBottom={null}
                        twoBottom={allResults[allResults.findIndex(element => element.id === 43)].last_two_number}
                        lastGrid={6}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <SingleResult
                        img={VietnamFlag}
                        name={'หวยฮานอย VIP'}
                        date={allResults[allResults.findIndex(element => element.id === 44)].period_name ? allResults[allResults.findIndex(element => element.id === 44)].period_name : '?'}
                        time={null}
                        firstResult={null}
                        threePrefix={allResults[allResults.findIndex(element => element.id === 44)].first_three_number}
                        threeBottom={null}
                        twoBottom={allResults[allResults.findIndex(element => element.id === 44)].last_two_number}
                        lastGrid={6}
                    />
                </Grid>
            </Grid>
            {/* END RESULTS */}
        </> : <></>
    )
}

export default ResultsPage;