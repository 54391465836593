import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Cookies from "universal-cookie";
import { logGetPromotionType } from "../../types";
import { ApiService } from "../../services/api.service";
import moment from "moment";

const SubLogGetPromotion = () => {
    const cookies = new Cookies(null, { path: '/' });
    const [transaction, setTransaction] = useState<logGetPromotionType[]>([]);

    useEffect(() => {
        getTransactions();
    }, []);

    const getTransactions = async () => {
        try {
            const service = new ApiService();
            const getTransaction = await service.getLogPromotion(cookies.get('access_token'));

            if(getTransaction.data.status_code === 200)
            {
                setTransaction(getTransaction.data.log);
            }
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <Box bgcolor={'white'} sx={{ borderRadius: '0px 0px 15px 15px' }}>
            <Box p={2}>
                <Typography align="center" fontSize={20} color={'#313131'}>
                    ประวัติรับโปรโมชั่น
                </Typography>
                <TableContainer component={Paper}>
                    <Table aria-label="deposit_transactions">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">โปรโมชั่น</TableCell>
                                <TableCell align="center">วันเวลาที่รับ</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                transaction.length > 0 ? transaction.map((element: logGetPromotionType, index: number) => {
                                    return (
                                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row" align="center">
                                                { element.name }
                                            </TableCell>
                                            <TableCell align="center">
                                                {
                                                    moment(element.createdAt).format('DD/MM/YYYY H:m:s')
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )
                                }) : <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                    <TableCell component="th" scope="row" align="center" colSpan={4}>
                                        ไม่พบประวัติรับโปรโมชั่น
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    )
}

export default SubLogGetPromotion;