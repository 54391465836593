import Cookies from "universal-cookie";
import { ApiService } from "../../../services/api.service";
import { useState, useEffect } from "react";
import { Button, Typography } from "@mui/material";
import { HiOutlineRefresh } from "react-icons/hi";
import Swal from "sweetalert2";
import LogoutIcon from '@mui/icons-material/Logout';
import { useMediaQuery } from "react-responsive";

interface PropsType {
    openDepositWithdrawMenu: (depositorwithdraw: number) => void;
}

const SubDetail = ({ openDepositWithdrawMenu }: PropsType) => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 890px)'
    });

    const cookies = new Cookies(null, { path: '/' });
    const [currentCredit, setCurrentCredit] = useState<number | null>(0);
    const [loadingCredit, setLoadingCredit] = useState<boolean>(false);

    useEffect(() => {
        if(cookies.get('access_token'))
        {
            getCredit();
        }
    }, []);

    const getCredit = async () => {
        if(!cookies.get('access_token'))
        {
            return;
        }
        else if(loadingCredit)
        {
            return;
        }

        setLoadingCredit(true);
        try {
            const service = new ApiService();
            const getCredit = await service.getCurrentCredit(cookies.get('access_token'));

            setCurrentCredit(getCredit.data.current_credit)
        } catch (err) {
            console.log(err);
        }
        setLoadingCredit(false);
    }

    const logout = () => {
        cookies.remove('access_token', {
            domain: 'lotto69.com', path: '/'
        });
        Swal.fire({
            icon: 'success',
            title: 'แจ้งเตือน',
            text: 'ออกจากระบบเรียบร้อยแล้วค่ะ'
        }).then((res) => {
            if(res)
            {
                window.location.reload();
            }
        });
    }

    return ( 
        <>
            <Button sx={
                {
                    mx: .5,
                    borderRadius: 2,
                    px: {
                        xs: .5,
                        sm: .5,
                        md: 3,
                        lg: 3
                    },
                    background: 'linear-gradient(to bottom, #7bcff7 1%, #47b4e5 99%)',
                    '&:hover': {
                        scale: '105%'
                    },
                    color: 'white',
                    textTransform: 'none',
                    height: '100%',
                    maxHeight: 38,
                    transition: 'all .2s'
                }
            } disableFocusRipple disableRipple onClick={() => openDepositWithdrawMenu(1)}>
                <Typography display={`flex`} alignContent={`center`} alignItems={`center`} sx={{
                    fontSize: {
                        xs: 10,
                        sm: 10,
                        md: 14,
                        lg: 14,
                    }
                }}>
                    ฝากเงิน
                </Typography>
            </Button>
            {/* <Button sx={
                {
                    mx: .5,
                    borderRadius: 2,
                    px: 3,
                    background: 'linear-gradient(to bottom, #ffb326 1%, #cc901f 99%)',
                    '&:hover': {
                        scale: '105%'
                    },
                    color: 'white',
                    textTransform: 'none',
                    height: '100%',
                    maxHeight: 38,
                    transition: 'all .2s'
                }
            } disableFocusRipple disableRipple>
                <Typography fontSize={14} display={`flex`} alignContent={`center`} alignItems={`center`}>
                    ถอนเงิน
                </Typography>
            </Button> */}
            <Button sx={
                {
                    cursor: 'default',
                    mx: .5,
                    borderRadius: 4,
                    px: {
                        xs: .5,
                        sm: .5,
                        md: 3,
                        lg: 3
                    },
                    backgroundColor: 'rgba(0, 0, 0, .5)',
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, .5)'
                    },
                    color: 'white',
                    textTransform: 'none',
                    height: '100%',
                    maxHeight: 38
                }
            } disableFocusRipple disableRipple>
                <Typography fontSize={14} display={`flex`} alignContent={`center`} alignItems={`center`}>
                    {parseFloat(String(currentCredit)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                    &nbsp;
                    <HiOutlineRefresh className={`${loadingCredit ? `rotating` : ""}`} color="#f4c53e" onClick={getCredit} style={{ cursor: 'pointer' }} />
                </Typography>
            </Button>
            {/* <Button sx={
                {
                    mx: .5,
                    borderRadius: 2,
                    px: 3,
                    background: 'linear-gradient(to bottom, #f90a2a 1%, #c30a2a 99%)',
                    color: 'white',
                    '&:hover': {
                        background: 'linear-gradient(to bottom, #f90a2a 1%, #c30a2a 99%)',
                    },
                    textTransform: 'none',
                    height: '100%',
                    maxHeight: 38
                }
            } onClick={logout}>
                <Typography display={`flex`} align="center" alignContent={`center`} alignItems={`center`} fontSize={14}>
                    {
                        isDesktopOrLaptop ? <>
                            <LogoutIcon /> ออกจากระบบ
                        </> : <LogoutIcon />
                    }
                </Typography>
            </Button> */}
        </>
    )
}

export default SubDetail;