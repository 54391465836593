import { Box, Button, Divider, Typography } from "@mui/material";
import Logo from './../../assets/images/logo.png';
import { useState, useEffect, useRef, MouseEvent, ChangeEvent, FormEvent } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { ApiService } from "../../services/api.service";
import Swal from "sweetalert2";
import SubLoginDesktop from "./SubLoginDesktop";
import RegisterDesktop from "./RegisterDesktop";

interface PropsType {
    isOpenState: boolean;
    setIsOpenState: (state: boolean) => void;
    loginOrRegister: number;
    setLoginOrRegister: (state: number) => void;
}

const LoginDesktop = ({ isOpenState, setIsOpenState, loginOrRegister, setLoginOrRegister }: PropsType) => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState<boolean>(true);

    useEffect(() => {
        setIsOpen(isOpenState);
    }, [isOpenState]);

    return (
        <>
            <Box
                position={`fixed`}
                width={`100%`}
                height={`100%`}
                display={isOpen ? `block` : `none`}
                flexDirection={`column`}
                justifyContent={`center`}
                alignContent={`center`}
                alignItems={`center`}
                color={`black`}
                zIndex={40}
                gap={2}
                // onClick={() => setIsOpenState(false)}
                sx={{
                    backgroundColor: {
                        xs: 'rgba(0, 0, 0, .6)',
                        md: 'rgba(0, 0, 0, .5)'
                    },
                    overflow: 'scroll'
                }}
            >
                <Box
                    display={`flex`}
                    flexDirection={`column`}
                    justifyContent={`center`}
                    alignContent={`center`}
                    alignItems={`center`}
                    gap={2}
                    sx={{ px: { xs: 3, md: 0 } }}
                >
                    <Box display={`flex`} gap={2}>
                        <Box sx={{
                            backgroundColor: 'white',
                            borderRadius: '15px 15px 15px 15px',
                            minWidth: '350px',
                            width: 'auto',
                            maxWidth: '500px'
                        }}>
                            <Box display={`flex`} justifyContent={`space-around`}>
                                <Box display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`} p={2} bgcolor={loginOrRegister === 2 ? `white` : `#a41b26`} width={`100%`}
                                    sx={{
                                        borderRadius: '15px 0px 0px 0px',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => setLoginOrRegister(2)}
                                >
                                    <Typography color={loginOrRegister === 2 ? `#dc202e` : `white`}>
                                        สมัครสมาชิก
                                    </Typography>
                                </Box>
                                <Box display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`} p={2} bgcolor={loginOrRegister === 1 ? `white` : `#a41b26`} width={`100%`}
                                    sx={{
                                        borderRadius: '0px 15px 0px 0px',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => setLoginOrRegister(1)}
                                >
                                    <Typography color={loginOrRegister === 1 ? `#dc202e` : `white`}>
                                        เข้าสู่ระบบ
                                    </Typography>
                                </Box>
                            </Box>
                            {
                                loginOrRegister === 1 ? <SubLoginDesktop /> : <RegisterDesktop />
                            }
                            {/* <form name="user_register" id="user_register" onSubmit={send_register}>
                                <Box bgcolor={'white'} sx={{ borderRadius: '0px 0px 15px 15px' }}>
                                    <Box p={2}>
                                        <Typography align="center" fontSize={20} color={'#313131'}>
                                            กรอกข้อมูลเพื่อสมัครสมาชิก
                                        </Typography>
                                        <Typography align="center" color={'#9c9c9c'}>
                                            กรุณาใช้ข้อมูลที่ถูกต้องและเป็นจริงในการสมัครเท่านั้น
                                        </Typography>
                                        <FormControl fullWidth sx={{ mt: 1 }}>
                                            <OutlinedInput
                                                name='user_phone'
                                                type={`tel`}
                                                size='small'
                                                fullWidth
                                                autoComplete="user_phone"
                                                placeholder="เบอร์โทรศัพท์"
                                                inputRef={usernameRef}
                                                sx={
                                                    {
                                                        backgroundColor: 'white'
                                                    }
                                                }
                                            />
                                        </FormControl>
                                        <FormControl fullWidth sx={{ mt: 1 }}>
                                            <OutlinedInput
                                                name='user_password'
                                                type={showPassword ? 'text' : 'password'}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                        >
                                                            {
                                                                showPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />
                                                            }
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                size='small'
                                                fullWidth
                                                autoComplete="user_password"
                                                placeholder="รหัสผ่าน"
                                                inputRef={passwordRef}
                                                sx={
                                                    {
                                                        backgroundColor: 'white'
                                                    }
                                                }
                                            />
                                        </FormControl>
                                        <Button type="submit" fullWidth sx={
                                                {
                                                    mt: 1,
                                                    py: 1,
                                                    backgroundColor: '#ec2230',
                                                    color: 'white',
                                                    '&:hover': {
                                                        backgroundColor: '#c61d29',
                                                        color: 'white',
                                                    }
                                                }
                                            } disabled={loading}>
                                                <Typography fontSize={16} fontWeight={500}>
                                                    {
                                                        loading ? <CircularProgress size={20} sx={
                                                            {
                                                                color: 'white'
                                                            }
                                                        } /> : "ยืนยัน"
                                                    }
                                                </Typography>
                                            </Button>
                                    </Box>
                                </Box>
                            </form> */}
                            <Box pb={1} px={2}>
                                <Divider sx={{ mt: -1 }} />
                                <Button fullWidth sx={
                                    {
                                        mt: 1,
                                        backgroundColor: '#9c9c9c',
                                        color: 'white',
                                        '&:hover': {
                                            backgroundColor: '#696969',
                                            color: 'white',
                                        }
                                    }
                                } onClick={() => setIsOpenState(false)}>
                                    <Typography fontSize={16} fontWeight={500}>
                                        ปิดหน้าต่าง
                                    </Typography>
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default LoginDesktop;