import { Button, Typography } from "@mui/material";

interface PropsType {
    openLoginRegisterMenu: (state: number) => void;
}

const SubLoginRegister = ({ openLoginRegisterMenu }: PropsType) => {
    return (
        <>
            <Button sx={
                {
                    mx: .5,
                    borderRadius: 2,
                    px: {
                        xs: .5,
                        sm: .5,
                        md: 2,
                        lg: 2
                    },
                    background: 'linear-gradient(to bottom, #7bcff7 1%, #47b4e5 99%)',
                    color: 'white',
                    '&:hover': {
                        background: 'linear-gradient(to bottom, #7bcff7 1%, #47b4e5 99%)',
                    },
                    textTransform: 'none',
                    height: '100%',
                    maxHeight: 38
                }
            } onClick={() => openLoginRegisterMenu(2)}>
                <Typography sx={{
                    fontSize: {
                        xs: 10,
                        sm: 10,
                        md: 14,
                        lg: 14,
                    }
                }}>
                    สมัครสมาชิก
                </Typography>
            </Button>
            <Button sx={
                {
                    mx: .5,
                    borderRadius: 2,
                    px: {
                        xs: .5,
                        sm: .5,
                        md: 3,
                        lg: 3
                    },
                    background: 'linear-gradient(to bottom, #a5e8a5 1%, #11c212 99%)',
                    color: 'white',
                    '&:hover': {
                        background: 'linear-gradient(to bottom, #a5e8a5 1%, #11c212 99%)',
                    },
                    textTransform: 'none',
                    height: '100%',
                    maxHeight: 38
                }
            } onClick={() => openLoginRegisterMenu(1)}>
                <Typography sx={{
                    fontSize: {
                        xs: 10,
                        sm: 10,
                        md: 14,
                        lg: 14,
                    }
                }}>
                    เข้าสู่ระบบ
                </Typography>
            </Button>
        </>
    )
}

export default SubLoginRegister;