import { Outlet, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { OutletContextProvider } from "./OutletContext";
import Header from "./components/Header/Header";
import { Container, Grid } from "@mui/material";
import backgroundCover from './assets/images/cover-bg-lotto.png';
import MenuMain from "./components/Menu/MenuMain";
import LoginDesktop from "./components/Login/LoginDesktop";
import DepositWithdrawMain from "./components/DepositWithdraw/DepositWithdrawMain";
import Banner from "./components/Header/Banner";
import Footer from "./components/Footer/Footer";
import DepositWithdrawTransaction from "./components/DepositWithdraw/DepositWithdrawTransaction";
import AffiliateMain from "./components/Affliate/AffiliateMain";
import SocketIO from "./components/SocketIO";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const App = () => {
    const cookies = new Cookies(null, { path: '/' });
    let location = useLocation();
    const query = useQuery();

    const by = query.get('by');
    const aff = query.get('aff');

    const [isOpenNavMenu, setIsOpenNavMenu] = useState<boolean>(false);
    const [isOpenLoginRegisterMenu, setIsOpenLoginRegisterMenu] = useState<boolean>(false);
    const [loginOrRegister, setLoginOrRegister] = useState<number>(1);
    const [isOpenDepositWithdrawMenu, setIsOpenDepositWithdrawMenu] = useState<boolean>(false);
    const [depositOrWithdraw, setDepositOrWithdraw] = useState<number>(1);
    const [isOpenDepositWithdrawTransactionsMenu, setIsOpenDepositWithdrawTransactionsMenu] = useState<boolean>(false);
    const [depositOrWithdrawTransactions, setDepositOrWithdrawTransactions] = useState<number>(1);
    const [isOpenAffiliateMenu, setIsOpenAffiliateMenu] = useState<boolean>(false);
    const [pathName, setPathName] = useState<string | null>(null);

    useEffect(() => {
        setPathName(location.pathname);

        if(location.pathname.includes("login") && !cookies.get('access_token'))
        {
            setLoginOrRegister(1);
            setIsOpenLoginRegisterMenu(true);
        }
        else if(location.pathname.includes("register") && !cookies.get('access_token'))
        {
            setLoginOrRegister(1);
            setIsOpenLoginRegisterMenu(true);
        }
        else if(location.pathname.includes("deposit") && cookies.get('access_token'))
        {
            setDepositOrWithdraw(1);
            setIsOpenDepositWithdrawMenu(true);
        }
        else if(location.pathname.includes("withdraw") && cookies.get('access_token'))
        {
            setDepositOrWithdraw(2);
            setIsOpenDepositWithdrawMenu(true);
        }
        else if(location.pathname.includes("logout") && cookies.get('access_token'))
        {
            cookies.remove('access_token', {
                domain: 'lotto69.com', path: '/'
            });
            Swal.fire({
                icon: 'success',
                title: 'แจ้งเตือน',
                text: 'ออกจากระบบเรียบร้อยแล้วค่ะ'
            }).then((res) => {
                if(res)
                {
                    window.location.reload();
                }
            });
        }

        window.scrollTo({
            top: 0,     // เลื่อนขึ้นไปที่ตำแหน่ง 0 (บนสุด)
            behavior: 'smooth' // เลื่อนด้วยการเคลื่อนไหวแบบนุ่มนวล
        });
    }, [location]);

    useEffect(() => {
        if(!cookies.get('access_token') && (aff || by))
        {
            setIsOpenLoginRegisterMenu(true);
            setLoginOrRegister(2);
        }
    }, [aff, by]);

    return (
        <OutletContextProvider>
            <MenuMain
                isOpenState={isOpenNavMenu}
                setIsOpenState={setIsOpenNavMenu}
                setDepositWithdrawIsOpenState={setIsOpenDepositWithdrawMenu}
                setDepositOrWithdraw={setDepositOrWithdraw}
                setIsOpenLoginRegisterState={setIsOpenLoginRegisterMenu}
                setLoginOrRegister={setLoginOrRegister}
                setDepositWithdrawTransactionsIsOpenState={setIsOpenDepositWithdrawTransactionsMenu}
                setDepositOrWithdrawTransactions={setDepositOrWithdrawTransactions}
            />
            <LoginDesktop isOpenState={isOpenLoginRegisterMenu} setIsOpenState={setIsOpenLoginRegisterMenu} loginOrRegister={loginOrRegister} setLoginOrRegister={setLoginOrRegister} />
            <Header
                isOpenState={isOpenNavMenu}
                setIsOpenState={setIsOpenNavMenu}
                isOpenLoginRegisterState={isOpenLoginRegisterMenu}
                setIsOpenLoginRegisterState={setIsOpenLoginRegisterMenu}
                loginOrRegister={loginOrRegister}
                setLoginOrRegister={setLoginOrRegister}
                isOpenDepositWithdrawMenu={isOpenDepositWithdrawMenu}
                setIsOpenDepositWithdrawMenu={setIsOpenDepositWithdrawMenu}
                depositOrWithdraw={depositOrWithdraw}
                setDepositOrWithdraw={setDepositOrWithdraw}
                setIsOpenAffiliateMenu={setIsOpenAffiliateMenu}
            />
            <DepositWithdrawMain isOpenState={isOpenDepositWithdrawMenu} setIsOpenState={setIsOpenDepositWithdrawMenu} depositOrWithdraw={depositOrWithdraw} setDepositOrWithdraw={setDepositOrWithdraw} />
            <DepositWithdrawTransaction
                isOpenState={isOpenDepositWithdrawTransactionsMenu}
                setIsOpenState={setIsOpenDepositWithdrawTransactionsMenu}
                depositOrWithdraw={depositOrWithdrawTransactions}
                setDepositOrWithdraw={setDepositOrWithdrawTransactions}
            />
            <AffiliateMain isOpenState={isOpenAffiliateMenu} setIsOpenState={setIsOpenAffiliateMenu} />
            {/* <Grid container>
                <Grid item xs={12}>
                    <img src={backgroundCover} alt="cover" style={{ width: '100%' }} />
                </Grid>
            </Grid> */}
            <Banner />
            {
                !pathName?.includes("/play/") ? <Container sx={
                    {
                        maxWidth: '1460px !important'
                    }
                }>
                    <Outlet></Outlet>
                </Container> : <Outlet></Outlet>
            }
            <Footer />
            <SocketIO />
        </OutletContextProvider>
    )
}

export default App;