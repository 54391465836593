import NavMenuDesktop from "./NavMenuDesktop";
import NavMenuMobile from "./NavMenuMobile";

interface PropsType {
    isOpenState: boolean;
    setIsOpenState: (state: boolean) => void;
    isOpenLoginRegisterState: boolean;
    setIsOpenLoginRegisterState: (state: boolean) => void;
    loginOrRegister: number;
    setLoginOrRegister: (state: number) => void;
    setIsOpenDepositWithdrawMenu: (state: boolean) => void;
    isOpenDepositWithdrawMenu: boolean;
    depositOrWithdraw: number;
    setDepositOrWithdraw: (state: number) => void;
    setIsOpenAffiliateMenu: (state: boolean) => void;
}

const Header = ({
    isOpenState,
    setIsOpenState,
    isOpenLoginRegisterState,
    setIsOpenLoginRegisterState,
    loginOrRegister,
    setLoginOrRegister,
    isOpenDepositWithdrawMenu,
    setIsOpenDepositWithdrawMenu,
    depositOrWithdraw,
    setDepositOrWithdraw,
    setIsOpenAffiliateMenu
}: PropsType) => {
    return (
        <>
            <NavMenuDesktop
                isOpenState={isOpenState}
                setIsOpenState={setIsOpenState}
                isOpenLoginRegisterState={isOpenLoginRegisterState}
                setIsOpenLoginRegisterState={setIsOpenLoginRegisterState}
                loginOrRegister={loginOrRegister}
                setLoginOrRegister={setLoginOrRegister}
                isOpenDepositWithdrawMenu={isOpenDepositWithdrawMenu}
                setIsOpenDepositWithdrawMenu={setIsOpenDepositWithdrawMenu}
                depositOrWithdraw={depositOrWithdraw}
                setDepositOrWithdraw={setDepositOrWithdraw}
                setIsOpenAffiliateMenu={setIsOpenAffiliateMenu}
            />
        </>
    )
}

export default Header;