import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { generateAllSwaps, generateNumbersWithPrefix } from "../../utils/utils";
import { useMediaQuery } from "react-responsive";

interface PropsType {
    amountNumber: number;
    subType: number;
    handlePushNumber: (number: string) => void;
    handleAddMultiNumber: (number: string[]) => void;
    deleteLastAdded: () => void;
}

const NumberSet = ({ amountNumber, subType, handlePushNumber, handleAddMultiNumber, deleteLastAdded }: PropsType) => {
    const isDesktop = useMediaQuery({
        query: '(min-width: 900px)'
    });

    const [number, setNumber] = useState<string[]>([]);
    const [currentStateNumber, setCurrentStateNumber] = useState<number>(0);

    useEffect(() => {
        if(amountNumber <= number.length)
        {
            let sendNumber: string = "";
            
            number.forEach((element: string) => {
                sendNumber = `${sendNumber}${element}`;
            });

            const allSwapsNumber: string[] = generateAllSwaps(sendNumber);
            if(amountNumber === 3 && subType === 2)
            {
                handleAddMultiNumber(allSwapsNumber);
            }
            else if(amountNumber === 2 && subType === 2 || amountNumber === 2 && subType === 4 || amountNumber === 2 && subType === 6)
            {
                handleAddMultiNumber(allSwapsNumber);
            }
            else
            {
                handleAddMultiNumber([sendNumber]);
            }
            
            setTimeout(() => setNumber([]), 200);
        }
    }, [number, amountNumber]);

    const handleAddNumber = (sendNumber: string) => {
        const allSwapsNumber: string[] = generateAllSwaps(sendNumber);
        if(amountNumber === 3 && subType === 2)
        {
            handleAddMultiNumber(allSwapsNumber);
        }
        else if(amountNumber === 2 && subType === 2 || amountNumber === 2 && subType === 4 || amountNumber === 2 && subType === 6)
        {
            handleAddMultiNumber(allSwapsNumber);
        }
        else
        {
            handleAddMultiNumber([sendNumber]);
        }
    }

    const handleDelelteLastNumber = () => {
        const tmp_number = [
            ...number
        ];

        tmp_number.pop();
        setNumber(tmp_number);
    }

    return (
        <Grid container spacing={1} mb={isDesktop ? 2 : 6}>
            {
                (amountNumber === 2 || amountNumber === 3) ? <>
                    {
                        [0,1,2,3,4,5,6,7,8,9].map((element: number) => (
                            <Grid item xs={isDesktop ? 2.4 : 4} key={element}>
                                <Button fullWidth
                                    sx={{
                                        background: currentStateNumber === element ? 'red' : 'rgba(255, 0, 0, .05)',
                                        backgroundSize: 'cover',
                                        border: '1px solid #bfbebe',
                                        borderColor: currentStateNumber === element ? 'rgb(255, 125, 5)' : 'red',
                                        borderRadius: '4px',
                                        transition: 'all .2s',
                                        cursor: 'pointer',
                                        WebkitJustifyContent: 'unset',
                                        justifyContent: 'unset',
                                        '&:hover': {
                                            background: currentStateNumber === element ? 'red' : 'rgba(255, 0, 0, .1)',
                                            fontWeight: 'bold',
                                            borderColor: 'red'
                                        },
                                        height: '100%'
                                    }}
                                    disableRipple
                                    disableFocusRipple
                                    onClick={() => setCurrentStateNumber(element)}
                                >
                                    <Box width={`100%`}>
                                        <Typography color={currentStateNumber === element ? 'white' : 'red'} fontWeight={`bold`} fontSize={11}>
                                            {element}{amountNumber === 3 ? "00" : amountNumber === 2 ? "0" : ""}
                                        </Typography>
                                    </Box>
                                </Button>
                            </Grid>
                        ))
                    }
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    {
                        generateNumbersWithPrefix(currentStateNumber, amountNumber).map((element: string) => (
                            <Grid item xs={isDesktop ? 2.4 : 4} key={element}>
                                <Button fullWidth
                                    sx={{
                                        backgroundColor: 'white',
                                        border: '1px solid #e8e8e8',
                                        borderColor: '#e8e8e8',
                                        borderRadius: '4px',
                                        transition: 'all .2s',
                                        cursor: 'pointer',
                                        WebkitJustifyContent: 'unset',
                                        justifyContent: 'unset',
                                        color: '#5e5e5e',
                                        '&:hover': {
                                            background: 'red',
                                            fontWeight: 'bold',
                                            borderColor: 'red',
                                            color: 'white',
                                        },
                                        height: '100%'
                                    }}
                                    disableRipple
                                    disableFocusRipple
                                    onClick={() => handleAddNumber(element)}
                                >
                                    <Box width={`100%`}>
                                        <Typography fontWeight={`bold`} fontSize={12}>
                                            {element}
                                        </Typography>
                                    </Box>
                                </Button>
                            </Grid>
                        ))
                    }
                </> : <>
                    {
                        [0,1,2,3,4,5,6,7,8,9].map((element: number) => (
                            <Grid item xs={isDesktop ? 2.4 : 4} key={element}>
                                <Button fullWidth
                                    sx={{
                                        backgroundColor: 'white',
                                        border: '1px solid #e8e8e8',
                                        borderColor: '#e8e8e8',
                                        borderRadius: '4px',
                                        transition: 'all .2s',
                                        cursor: 'pointer',
                                        WebkitJustifyContent: 'unset',
                                        justifyContent: 'unset',
                                        color: '#5e5e5e',
                                        '&:hover': {
                                            background: 'red',
                                            fontWeight: 'bold',
                                            borderColor: 'red',
                                            color: 'white',
                                        },
                                        height: '100%'
                                    }}
                                    disableRipple
                                    disableFocusRipple
                                    onClick={() => handleAddNumber(String(element))}
                                >
                                    <Box width={`100%`}>
                                        <Typography fontWeight={`bold`} fontSize={11}>
                                            {element}
                                        </Typography>
                                    </Box>
                                </Button>
                            </Grid>
                        ))
                    }
                </>
            }
        </Grid>
    )
}

export default NumberSet;