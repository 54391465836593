import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import App from './App';
import Home from './components/Home';
import PlayLotto from './components/Lotto/PlayLotto';
import ResultsPage from './components/Results/ResultsPage';
import PromotionMain from './components/Promotion.tsx/PromotionMain';
import LottoRate from './components/PlayLottoRate/LottoRate';
import MemberLottoBill from './components/MemberLottoBill/MemberLottoBill';

// CSS
import './assets/css/index.css';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
    {
        element: <App />,
        children: [
            {
                path: "/", element: <Home />
            },
            {
                path: "/game/:game_tab", element: <Home />
            },
            {
                path: "/login", element: <Home />
            },
            {
                path: "/register", element: <Home />
            },
            {
                path: "/deposit", element: <Home />
            },
            {
                path: "/withdraw", element: <Home />
            },
            {
                path: "/logout", element: <Home />
            },
            {
                path: '/play/:lotto_id', element: <PlayLotto />
            },
            {
                path: "/promotions", element: <PromotionMain />
            },
            {
                path: "/results", element: <ResultsPage />
            },
            {
                path: "/payrate", element: <LottoRate />
            },
            {
                path: "/bills", element: <MemberLottoBill />
            },
            {
                path: "*", element: <Home /> // เส้นทางสำหรับหน้า 404
            }
        ]
    }
]);

root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
