import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Box, Button, CircularProgress, FormControl, IconButton, InputAdornment, OutlinedInput, Typography } from "@mui/material";
import { useState, useEffect, useRef, MouseEvent, ChangeEvent, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import { ApiService } from "../../services/api.service";

const SubLoginDesktop = () => {
    const navigate = useNavigate();
    const cookies = new Cookies(null, { path: '/' });
    const [loading, setLoading] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    const usernameRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [rememberMe, _setRememberMe] = useState<boolean>(true);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = (e: MouseEvent<HTMLButtonElement>) => e.preventDefault();

    useEffect(() => {
        const ref = document.referrer;
        if(!cookies.get('referrer') || cookies.get('referrer') === '')
        {
            cookies.set('referrer', ref, {
                domain: 'lotto69.com', // ไม่ใส่จุดนำหน้า
                path: '/',
            });
        }
    }, []);

    useEffect(() => {
        (async () => {
            try {
                if(!cookies.get('access_token'))
                {
                    setLoaded(true);
                    return;
                }

                const access_token: string = cookies.get('access_token');
                const service = new ApiService();
                const checkToken = (await service.check_access_token(access_token)).data;

                if(checkToken.status_code !== 200)
                {
                    setLoaded(true);
                    return cookies.remove('access_token');
                }
                // else if(checkToken.status_code === 200)
                // {
                //     return navigate('/game/slot');
                // }
            } catch (err) {
                console.log(err);
            }
        })();
    }, []);

    const send_login = async (e: FormEvent) => {
        e.preventDefault();
        
        try {
            if(!usernameRef.current || !passwordRef.current)
            {
                return;
            }

            const phone = usernameRef.current.value.trim();
            const password = passwordRef.current.value.trim();

            if(phone === '')
            {
                return Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: 'เบอร์โทรศัพท์ไม่สามารถเว้นว่างได้'
                });
            }
            else if(password === '')
            {
                return Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: 'รหัสผ่านไม่สามารถเว้นว่างได้'
                });
            }

            setLoading(true);
            const service = new ApiService();
            const login = await service.login({
                phone,
                password,
                rememberMe
            });
            const { data } = login;
            setLoading(false);

            if(data.status_code === 200 && data.message === 'Success')
            {
                const expires = new Date();
                expires.setDate(expires.getDate() + (rememberMe ? 7 : 1));

                cookies.set('access_token', data.member_data.access_token, { expires, domain: 'lotto69.com', path: '/' });
                cookies.set('user', data.member_data.user, { expires, domain: 'lotto69.com', path: '/' });
                return window.location.reload();
                // return navigate('/game/slot');
            }

            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: data.message || 'ไม่ทราบสาเหตุข้อผิดพลาด'
            });
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <form name="user_login" id="user_login" onSubmit={send_login}>
            <Box bgcolor={'white'} sx={{ borderRadius: '0px 0px 15px 15px' }}>
                <Box p={2}>
                    <Typography align="center" fontSize={20} color={'#313131'}>
                        กรอกหมายเลขโทรศัพท์
                    </Typography>
                    <Typography align="center" color={'#9c9c9c'}>
                        เบอร์โทรศัพท์ที่ใช้ในการสมัครเพื่อล็อคอิน
                    </Typography>
                    <FormControl fullWidth sx={{ mt: 1 }}>
                        <OutlinedInput
                            name='user_phone'
                            type={`tel`}
                            size='small'
                            fullWidth
                            autoComplete="user_phone"
                            placeholder="เบอร์โทรศัพท์"
                            inputRef={usernameRef}
                            sx={
                                {
                                    backgroundColor: 'white'
                                }
                            }
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 1 }}>
                        <OutlinedInput
                            name='user_password'
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {
                                            showPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />
                                        }
                                    </IconButton>
                                </InputAdornment>
                            }
                            size='small'
                            fullWidth
                            autoComplete="user_password"
                            placeholder="รหัสผ่าน"
                            inputRef={passwordRef}
                            sx={
                                {
                                    backgroundColor: 'white'
                                }
                            }
                        />
                    </FormControl>
                    <Button type="submit" fullWidth sx={
                            {
                                mt: 1,
                                py: 1,
                                backgroundColor: '#ec2230',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: '#c61d29',
                                    color: 'white',
                                }
                            }
                        } disabled={loading}>
                            <Typography fontSize={16} fontWeight={500}>
                                {
                                    loading ? <CircularProgress size={20} sx={
                                        {
                                            color: 'white'
                                        }
                                    } /> : "ยืนยัน"
                                }
                            </Typography>
                        </Button>
                </Box>
            </Box>
        </form>
    )
}

export default SubLoginDesktop;