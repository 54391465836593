import { Box, Button, Divider, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { ApiService } from "../../services/api.service";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DiceSpin from "../Loading/DiceSpin";

interface PropsType {
    isOpenState: boolean;
    setIsOpenState: (state: boolean) => void;
    billId: number;
    billUuid: string;
}

const PopupMemberLottoBill = ({ isOpenState, setIsOpenState, billId, billUuid }: PropsType) => {
    const [isOpen, setIsOpen] = useState<boolean>(true);
    const cookies = new Cookies(null, { path: '/' });
    const [loading, setLoading] = useState<boolean>(false);
    const [billData, setBillData] = useState<{
        number: string,
        subType: number,
        amountNumberState: number,
        price: number,
        pay: number
    }[]>([]);

    useEffect(() => {
        setIsOpen(isOpenState);
        if(!isOpenState || billId === 0)
        {
            setBillData([]);
            return;
        }
        loadBill();
    }, [billId, isOpenState]);

    const loadBill = async () => {
        if(loading)
        {
            return;
        }

        setLoading(true);
        try {
            const service = new ApiService();
            const getBill = await service.getBillById(cookies.get('access_token'), billId);

            if(getBill.data.status_code === 200)
            {
                setBillData(getBill.data.bill);
            }
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
        setLoading(false);
    }

    return (
        <>
            <Box
                position={`fixed`}
                width={`100%`}
                height={`100%`}
                display={isOpen ? `block` : `none`}
                flexDirection={`column`}
                justifyContent={`center`}
                alignContent={`center`}
                alignItems={`center`}
                color={`black`}
                zIndex={40}
                left={0}
                top={0}
                gap={2}
                sx={{
                    backgroundColor: {
                        xs: 'rgba(0, 0, 0, .6)',
                        md: 'rgba(0, 0, 0, .5)'
                    },
                    overflow: 'scroll'
                }}
            >
                <Box
                    display={`flex`}
                    flexDirection={`column`}
                    justifyContent={`center`}
                    alignContent={`center`}
                    alignItems={`center`}
                    gap={2}
                    sx={{ px: { xs: 3, md: 0 } }}
                >
                    <Box display={`flex`} gap={2}>
                        <Box sx={{
                            backgroundColor: 'white',
                            borderRadius: '15px 15px 15px 15px',
                            minWidth: '350px',
                            width: 'auto',
                            maxWidth: '500px'
                        }}>
                            <Box bgcolor={'white'} sx={{ borderRadius: '15px 15px 15px 15px' }}>
                                <Box p={2}>
                                    <Typography align="center" fontSize={20} color={'#313131'}>
                                        โพยของคุณ
                                    </Typography>
                                    <Typography display={`flex`} justifyContent={`center`} fontSize={14} color={'#9c9c9c'}>
                                        { billUuid }
                                    </Typography>
                                </Box>
                            </Box>
                            <Box pb={1} px={2}>
                                {
                                    loading ? <Box my={3} display={`flex`} justifyContent={`center`}>
                                        <DiceSpin />
                                    </Box> : <TableContainer component={Paper}>
                                        <Table aria-label="bill_detail">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center">เลข</TableCell>
                                                    <TableCell align="center">ประเภท</TableCell>
                                                    <TableCell align="center">ราคา</TableCell>
                                                    <TableCell align="center">อัตราจ่าย</TableCell>
                                                    <TableCell align="center">ราคาจ่าย</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    billData.length > 0 ? billData.map((element: {
                                                        number: string,
                                                        subType: number,
                                                        amountNumberState: number,
                                                        price: number,
                                                        pay: number
                                                    }, index: number) => {
                                                        return (
                                                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                                <TableCell component="th" scope="row" align="center">
                                                                    { element.number }
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    {element.amountNumberState === 3 && element.subType === 1
                                                                    ? "3 ตัวบน"
                                                                    : element.amountNumberState === 3 && element.subType === 3
                                                                    ? "3 ตัวบนโต๊ด"
                                                                    : element.amountNumberState === 2 && element.subType === 1
                                                                    ? "2 ตัวบน"
                                                                    : element.amountNumberState === 2 && element.subType === 3
                                                                    ? "2 ตัวล่าง"
                                                                    : element.amountNumberState === 4 && element.subType === 1
                                                                    ? "วิ่งบน"
                                                                    : element.amountNumberState === 4 && element.subType === 2
                                                                    ? "วิ่งล่าง"
                                                                    : element.amountNumberState}
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    ฿{
                                                                        parseFloat(String(element.price)).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                    }
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    x{
                                                                        element.pay
                                                                    }
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    ฿{
                                                                        parseFloat(String(element.price * element.pay)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    }) : <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        <TableCell component="th" scope="row" align="center" colSpan={5}>
                                                            ไม่พบข้อมูลบิล
                                                        </TableCell>
                                                    </TableRow>
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }
                                <Divider sx={{ mt: 1 }} />
                                <Button fullWidth sx={
                                    {
                                        mt: 1,
                                        backgroundColor: '#9c9c9c',
                                        color: 'white',
                                        '&:hover': {
                                            backgroundColor: '#696969',
                                            color: 'white',
                                        }
                                    }
                                } onClick={() => setIsOpenState(false)}>
                                    <Typography fontSize={16} fontWeight={500}>
                                        ปิดหน้าต่าง
                                    </Typography>
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default PopupMemberLottoBill;