type CooldownCallback = () => void;

const generateRandomString = (length: number) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    
    return result;
}

const getPermutations = (string: string) => {
    if (string.length <= 1) {
        return [string];
    }

    let allPermutations = [];
    for (let i = 0; i < string.length; i++) {
        const char = string[i];
        const remainingString: string = string.slice(0, i) + string.slice(i + 1);
        const remainingPermutations: string[] = getPermutations(remainingString);
        for (let perm of remainingPermutations) {
            allPermutations.push(char + perm);
        }
    }

    return allPermutations;
}

const generateAllSwaps = (string: string): string[] => {
    const swaps = new Set<string>();
    const permutations = getPermutations(string);
    for (let perm of permutations) {
        swaps.add(perm);
    }
    return Array.from(swaps);
}

const generateNumbersWithPrefix = (prefix: number, amountNumberState: number): string[] => {    
    const numbers: string[] = [];
    for (let i = 0; i < (amountNumberState === 3 ? 100 : 10); i++) {
        numbers.push(`${prefix}${i.toString().padStart(amountNumberState - 1, '0')}`);
    }
    return numbers;
}

const startCooldown = (endTime: Date, onCooldownComplete: CooldownCallback): void => {
    const intervalId = setInterval(() => {
        const currentTime = new Date();
        const remainingTime = Math.max(0, endTime.getTime() - currentTime.getTime()) / 1000; // remaining time in seconds
    
        const hours = Math.floor(remainingTime / 3600);
        const minutes = Math.floor((remainingTime % 3600) / 60);
        const seconds = Math.floor(remainingTime % 60);
        
        // if (remainingTime <= 0) {
        //     clearInterval(intervalId);
        //     onCooldownComplete();
        // }

        if (hours > 0) {
            return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
            console.log(`Cooldown: ${hours}:${minutes.toString().padStart(2, '0')}`);
        } else {
            return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
            console.log(`Cooldown: ${minutes}:${seconds.toString().padStart(2, '0')}`);
        }
    }, 1000);
}

export { generateRandomString, generateAllSwaps, generateNumbersWithPrefix, startCooldown }