import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ApiService } from "../../services/api.service";
import Swal from "sweetalert2";
import { gameListType, lottoListType, providersType } from "../../types";
import Cookies from "universal-cookie";

import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { FaPlay } from "react-icons/fa6";
import LivePicture from './../../assets/images/ajax-ic-live-animate.webp';
import DiceSpin from "../Loading/DiceSpin";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import './../../assets/css/gamehome.css';
import { useMediaQuery } from "react-responsive";

interface PropsType {
    game: string
}

const GameTab = ({ game }: PropsType) => {
    const cookies = new Cookies(null, {path: '/'});
    const navigate = useNavigate();
    const [gameList, setGameList] = useState<gameListType[]>([]);
    const [providers, setProviders] = useState<providersType[]>([]);
    const [currentProviders, setCurrentProviders] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [providerOffsets, setProviderOffsets] = useState<number>(0);
    const [providerBottom, setProviderBottom] = useState<boolean>(false);
    const [providerHeight, setProviderHeight] = useState<number | null>(null);
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 640px)'
    });

    useEffect(() => {
        loadGameData();
        setProviderOffsets(0);
        setProviderBottom(false);
        window.scrollTo({
            top: 0,     // เลื่อนขึ้นไปที่ตำแหน่ง 0 (บนสุด)
            behavior: 'smooth' // เลื่อนด้วยการเคลื่อนไหวแบบนุ่มนวล
        });
    }, [game]);

    const loadGameData = async () => {
        setLoading(true);
        try {
            setProviders([]);
            const service = new ApiService();
            const gamesData = await service.gameGamesList(game);
            const getProviders = await service.getProvidersList(game);

            if(gamesData.data.status_code === 200)
            {
                const providersData = getProviders.data.providers.filter((element: providersType) => {
                    return element.provider, gamesData.data.games.findIndex((element2: gameListType) => element2.provider === element.provider) !== -1 ? true : false;
                });
                setProviders(providersData);
                setTimeout(() => {
                    setGameList(gamesData.data.games);
                    setLoading(false);
                }, 1000);
            }
            else
            {
                Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: 'ไม่สามารถโหลดข้อมูลเกมได้ในขณะนี้ กรุณาลองรีเฟรชหน้าเว็บใหม่อีกครั้ง'
                });
            }
        } catch (err) {
            console.log(err);
        }
    }

    const createRedirect = async (game_id: string, provider: string, tab: string) => {
        if(loading)
        {
            return;
        }

        setLoading(true);
        try {
            if(!cookies.get('access_token'))
            {
                setLoading(false);
                return Swal.fire({
                    icon: 'warning',
                    title: 'แจ้งเตือน',
                    text: 'กรุณาเข้าสู่ระบบก่อนเข้าเล่นค่ะ'
                });
            }

            const service = new ApiService();
            let getRedirectGameUrl = await service.getRedirectGameUrl(cookies.get('access_token'), game_id, provider, tab);

            if(getRedirectGameUrl.data.status_code !== 200)
            {
                getRedirectGameUrl = await service.getRedirectLoginUrl(cookies.get('access_token'));
            }

            const userAgent = navigator.userAgent.toLowerCase();
            const safari = /safari/.test(userAgent) && !/chrome/.test(userAgent);

            if(safari)
            {
                window.location.href = getRedirectGameUrl.data.url;
            }
            else
            {
                const link = document.createElement('a');
                link.href = getRedirectGameUrl.data.url;
                link.target = '_blank';
                link.click();
            }
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }

    // const toggleVisibleProviders = () => {
    //     const element = document.getElementById("provider_list") as HTMLElement;
    //     const elementProviderListMain = document.getElementById("provider_list_main") as HTMLElement;
    //     if(!element)
    //     {
    //         return;
    //     }

    //     const stickyOffset = element.offsetTop;
    //     const setTop = 60;

    //     const pageHeight = document.documentElement.scrollHeight;

    //     if(window.pageYOffset > (pageHeight - 1300))
    //     {
    //         // console.log(1)
    //         setProviderBottom(true);
    //         element.style.position = 'static';
    //     }
    //     else if (window.pageYOffset > (stickyOffset > setTop ? stickyOffset : providerOffsets)) {
    //         // console.log(2)
    //         setProviderBottom(false);
    //         if(stickyOffset > setTop)
    //         {
    //             setProviderOffsets(stickyOffset);
    //         }
    //         element.style.position = 'fixed';
    //         element.style.top = `${setTop}px`;
    //         element.style.width = '50px';
    //     }
    //     else
    //     {
    //         // console.log(3)
    //         setProviderBottom(false);
    //         element.style.position = 'static';
    //     }
    // }

    // window.addEventListener('scroll', toggleVisibleProviders);

    return (
        !loading ? <Container sx={{ maxWidth: '1920px !important' }}>
            <Grid container spacing={2}>
                {/* <Grid item xs={2} sm={1.5} md={1} lg={.5} sx={{
                    display: providerBottom ? 'flex' : 'unset',
                    justifyContent: providerBottom ? 'flex-end' : 'unset',
                    flexDirection: providerBottom ? 'column' : 'unset'
                }} id="provider_list_main">
                    <Box id="provider_list" sx={{
                        maxHeight: '93dvh',
                        height: '100%`',
                        overflowY: 'scroll',
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                        '-ms-overflow-style': 'none',
                        'scrollbar-width': 'none'
                    }}>
                        {
                            providers.sort((a: providersType, b: providersType) => {
                                if (a.provider === "PGS") return -1;
                                if (b.provider === "PGS") return 1;

                                return 0;
                            }).map((element: providersType, index: number) => {
                                return (
                                    <Box sx={
                                        {
                                            cursor: 'pointer',
                                            py: .5,
                                            marginLeft: '6px'
                                        }
                                    } onClick={() => setCurrentProviders(index)} key={index}>
                                        <img
                                            src={element.logoURL}
                                            alt={element.providerName}
                                            style={{ 
                                                borderRadius: 20,
                                                width: `100%`,
                                                maxHeight: 200,
                                                display: 'block',
                                            }}
                                        />
                                    </Box>
                                )
                            })
                        }
                    </Box>
                </Grid> */}
                <Grid item xs={12}>
                    <Swiper
                        slidesPerView={isDesktopOrLaptop ? 14 : 6}
                        spaceBetween={10}
                        className="top-10-game-swiper"
                    >
                        {
                            providers.sort((a: providersType, b: providersType) => {
                                if(game === 'slot')
                                {
                                    if (a.provider === "PGS") return -1;
                                    if (b.provider === "PGS") return 1;
                                    if (a.provider === "PMTS") return -1;
                                    if (b.provider === "PMTS") return 1;
                                    if (a.provider === "PMT") return -1;
                                    if (b.provider === "PMT") return 1;
                                    if (a.provider === "NJK") return -1;
                                    if (b.provider === "NJK") return 1;
                                    if (a.provider === "JL") return -1;
                                    if (b.provider === "JL") return 1;
                                    if (a.provider === "SEXS") return -1;
                                    if (b.provider === "SEXS") return 1;
                                    if (a.provider === "JOK") return -1;
                                    if (b.provider === "JOK") return 1;
                                    if (a.provider === "L22") return -1;
                                    if (b.provider === "L22") return 1;
                                    if (a.provider === "AMBS") return -1;
                                    if (b.provider === "AMBS") return 1;
                                }
                                else if(game === 'casino')
                                {
                                    if (a.provider === "SEX") return -1;
                                    if (b.provider === "SEX") return 1;
                                    if (a.provider === "PTGC") return -1;
                                    if (b.provider === "PTGC") return 1;
                                    if (a.provider === "SAG") return -1;
                                    if (b.provider === "SAG") return 1;
                                    if (a.provider === "AG") return -1;
                                    if (b.provider === "AG") return 1;
                                    if (a.provider === "BIGG") return -1;
                                    if (b.provider === "BIGG") return 1;
                                    if (a.provider === "WM") return -1;
                                    if (b.provider === "WM") return 1;
                                    if (a.provider === "PMT") return -1;
                                    if (b.provider === "PMT") return 1;
                                    if (a.provider === "AB") return -1;
                                    if (b.provider === "AB") return 1;
                                }

                                return 0;
                            }).map((element: providersType, index: number) => {
                                return (
                                    <SwiperSlide key={index}>
                                        <Box sx={
                                            {
                                                cursor: 'pointer'   
                                            }
                                        } onClick={() => setCurrentProviders(index)}>
                                            <img
                                                src={element.logoURL}
                                                alt={`${element.providerName}`}
                                                style={{ 
                                                    width: `100%`,
                                                    maxHeight: 200,
                                                    display: 'block',
                                                }}
                                            />
                                        </Box>
                                    </SwiperSlide>
                                    // <Box sx={
                                    //     {
                                    //         cursor: 'pointer',
                                    //         py: .5,
                                    //         marginLeft: '6px'
                                    //     }
                                    // } onClick={() => setCurrentProviders(index)} key={index}>
                                    //     <img
                                    //         src={element.logoURL}
                                    //         alt={element.providerName}
                                    //         style={{ 
                                    //             borderRadius: 20,
                                    //             width: `100%`,
                                    //             maxHeight: 200,
                                    //             display: 'block',
                                    //         }}
                                    //     />
                                    // </Box>
                                )
                            })
                        }
                        
                    </Swiper>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={2} id="game_list">
                        {
                            gameList.filter((element: gameListType) => element.provider === providers[currentProviders].provider).map((element: gameListType, index: number) => {
                                return (
                                    <Grid item xs={4} sm={3} md={2} lg={1.5} key={`${element.gameName}-${index}`}>
                                        <Box sx={
                                            {
                                                cursor: 'pointer'   
                                            }
                                        }>
                                            <Box style={{ position: 'relative', textAlign: 'center' }}>
                                                <Box sx={{
                                                    position: 'absolute',
                                                    backgroundColor: 'rgba(0, 0, 0, .5)',
                                                    height: '100%',
                                                    width: '100%',
                                                    color: 'black',
                                                    opacity: '0%',
                                                    borderRadius: 2,
                                                    zIndex: 10,
                                                    transition: 'all .2s',
                                                    '&:hover': {
                                                        opacity: '100%'
                                                    },
                                                    display: 'flex',
                                                    alignContent: 'center',
                                                    alignItems: 'center'
                                                }}>
                                                    <Box width={`100%`} display={`flex`} justifyContent={`center`}>
                                                        <Button sx={{
                                                            backgroundColor: '#d3202d',
                                                            color: 'white',
                                                            '&:hover': {
                                                                backgroundColor: '#a01822'
                                                            }
                                                        }} onClick={() => {
                                                            createRedirect(element.game_id, element.provider, element.gameCategory)
                                                        }}>
                                                            <Typography align="center" display={`flex`} alignContent={`center`} alignItems={`center`} py={.3} px={1} fontSize={14}>
                                                                <FaPlay />&nbsp;เข้าเล่น
                                                            </Typography>
                                                        </Button>
                                                    </Box>
                                                </Box>
                                                <img
                                                    src={element.image_vertical}
                                                    alt={element.gameName}
                                                    style={{ 
                                                        borderRadius: 10,
                                                        width: `100%`,
                                                        maxHeight: 200, 
                                                        display: 'block' 
                                                    }}
                                                />
                                                <div style={{
                                                    position: 'absolute',
                                                    bottom: '0',
                                                    color: 'white',
                                                    background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, .8), rgba(0, 0, 0, 1))',
                                                    transform: 'translate(-50%, 0%)',
                                                    left: '50%',
                                                    padding: '10px',
                                                    borderRadius: '5px',
                                                    maxWidth: '87%',
                                                    minWidth: '50%',
                                                    width: '100%'
                                                }}>
                                                    <Typography fontSize={12} align='center'>
                                                        {
                                                            element.provider
                                                        }
                                                        &nbsp;
                                                        {
                                                            element.gameName
                                                        }
                                                    </Typography>
                                                </div>
                                            </Box>
                                        </Box>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Container> : <Box width={`100%`} display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`} mt={3}>
            <DiceSpin />
        </Box>
    )
}

export default GameTab;