import { Box, Divider, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

const LottoRate = () => {
    return (
        <Box p={2} sx={{ color: 'black' }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography fontSize={20}>
                        อัตราจ่ายหวย
                    </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Box sx={{
                        backgroundColor: 'white',
                        color: 'black',
                        boxShadow: "0 4px 4px 2px rgba(0, 0, 0, 0.15)",
                        borderRadius: 2
                    }}>
                        <Box sx={{ p: 2 }}>
                            <Typography>
                                หวยไทย
                            </Typography>
                            <TableContainer>
                                <Table aria-label="simple table">
                                    <TableHead sx={{ backgroundColor: '#cf1f2d' }}>
                                        <TableRow>
                                            <TableCell>
                                                <Typography fontSize={14} color={'white'}>
                                                    ประเภท
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography fontSize={14} color={'white'}>
                                                    ราคา
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography fontSize={14} color={'white'}>
                                                    อัตราจ่าย
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow sx={{
                                            transition: 'all .2s',
                                            '&:last-child td, &:last-child th': {
                                                border: 0
                                            },
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, .05)'
                                            }
                                        }}>
                                            <TableCell component="th" scope="row">
                                                3 ตัวบน
                                            </TableCell>
                                            <TableCell align="center">
                                                บาทละ
                                            </TableCell>
                                            <TableCell align="right">
                                                900
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{
                                            transition: 'all .2s',
                                            '&:last-child td, &:last-child th': {
                                                border: 0
                                            },
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, .05)'
                                            }
                                        }}>
                                            <TableCell component="th" scope="row">
                                                3 ตัวโต๊ด
                                            </TableCell>
                                            <TableCell align="center">
                                                บาทละ
                                            </TableCell>
                                            <TableCell align="right">
                                                150
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{
                                            transition: 'all .2s',
                                            '&:last-child td, &:last-child th': {
                                                border: 0
                                            },
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, .05)'
                                            }
                                        }}>
                                            <TableCell component="th" scope="row">
                                                2 ตัวบน
                                            </TableCell>
                                            <TableCell align="center">
                                                บาทละ
                                            </TableCell>
                                            <TableCell align="right">
                                                90
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{
                                            transition: 'all .2s',
                                            '&:last-child td, &:last-child th': {
                                                border: 0
                                            },
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, .05)'
                                            }
                                        }}>
                                            <TableCell component="th" scope="row">
                                                2 ตัวล่าง
                                            </TableCell>
                                            <TableCell align="center">
                                                บาทละ
                                            </TableCell>
                                            <TableCell align="right">
                                                90
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{
                                            transition: 'all .2s',
                                            '&:last-child td, &:last-child th': {
                                                border: 0
                                            },
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, .05)'
                                            }
                                        }}>
                                            <TableCell component="th" scope="row">
                                                วิ่งบน
                                            </TableCell>
                                            <TableCell align="center">
                                                บาทละ
                                            </TableCell>
                                            <TableCell align="right">
                                                3.2
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{
                                            transition: 'all .2s',
                                            '&:last-child td, &:last-child th': {
                                                border: 0
                                            },
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, .05)'
                                            }
                                        }}>
                                            <TableCell component="th" scope="row">
                                                วิ่งล่าง
                                            </TableCell>
                                            <TableCell align="center">
                                                บาทละ
                                            </TableCell>
                                            <TableCell align="right">
                                                4.2
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Box sx={{
                        backgroundColor: 'white',
                        color: 'black',
                        boxShadow: "0 4px 4px 2px rgba(0, 0, 0, 0.15)",
                        borderRadius: 2
                    }}>
                        <Box sx={{ p: 2 }}>
                            <Typography>
                                หวยฮานอย
                            </Typography>
                            <TableContainer>
                                <Table aria-label="simple table">
                                    <TableHead sx={{ backgroundColor: '#cf1f2d' }}>
                                        <TableRow>
                                            <TableCell>
                                                <Typography fontSize={14} color={'white'}>
                                                    ประเภท
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography fontSize={14} color={'white'}>
                                                    ราคา
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography fontSize={14} color={'white'}>
                                                    อัตราจ่าย
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow sx={{
                                            transition: 'all .2s',
                                            '&:last-child td, &:last-child th': {
                                                border: 0
                                            },
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, .05)'
                                            }
                                        }}>
                                            <TableCell component="th" scope="row">
                                                3 ตัวบน
                                            </TableCell>
                                            <TableCell align="center">
                                                บาทละ
                                            </TableCell>
                                            <TableCell align="right">
                                                900
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{
                                            transition: 'all .2s',
                                            '&:last-child td, &:last-child th': {
                                                border: 0
                                            },
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, .05)'
                                            }
                                        }}>
                                            <TableCell component="th" scope="row">
                                                3 ตัวโต๊ด
                                            </TableCell>
                                            <TableCell align="center">
                                                บาทละ
                                            </TableCell>
                                            <TableCell align="right">
                                                150
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{
                                            transition: 'all .2s',
                                            '&:last-child td, &:last-child th': {
                                                border: 0
                                            },
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, .05)'
                                            }
                                        }}>
                                            <TableCell component="th" scope="row">
                                                2 ตัวบน
                                            </TableCell>
                                            <TableCell align="center">
                                                บาทละ
                                            </TableCell>
                                            <TableCell align="right">
                                                90
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{
                                            transition: 'all .2s',
                                            '&:last-child td, &:last-child th': {
                                                border: 0
                                            },
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, .05)'
                                            }
                                        }}>
                                            <TableCell component="th" scope="row">
                                                2 ตัวล่าง
                                            </TableCell>
                                            <TableCell align="center">
                                                บาทละ
                                            </TableCell>
                                            <TableCell align="right">
                                                90
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{
                                            transition: 'all .2s',
                                            '&:last-child td, &:last-child th': {
                                                border: 0
                                            },
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, .05)'
                                            }
                                        }}>
                                            <TableCell component="th" scope="row">
                                                วิ่งบน
                                            </TableCell>
                                            <TableCell align="center">
                                                บาทละ
                                            </TableCell>
                                            <TableCell align="right">
                                                3.2
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{
                                            transition: 'all .2s',
                                            '&:last-child td, &:last-child th': {
                                                border: 0
                                            },
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, .05)'
                                            }
                                        }}>
                                            <TableCell component="th" scope="row">
                                                วิ่งล่าง
                                            </TableCell>
                                            <TableCell align="center">
                                                บาทละ
                                            </TableCell>
                                            <TableCell align="right">
                                                4.2
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Box sx={{
                        backgroundColor: 'white',
                        color: 'black',
                        boxShadow: "0 4px 4px 2px rgba(0, 0, 0, 0.15)",
                        borderRadius: 2
                    }}>
                        <Box sx={{ p: 2 }}>
                            <Typography>
                                หวยลาว
                            </Typography>
                            <TableContainer>
                                <Table aria-label="simple table">
                                    <TableHead sx={{ backgroundColor: '#cf1f2d' }}>
                                        <TableRow>
                                            <TableCell>
                                                <Typography fontSize={14} color={'white'}>
                                                    ประเภท
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography fontSize={14} color={'white'}>
                                                    ราคา
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography fontSize={14} color={'white'}>
                                                    อัตราจ่าย
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow sx={{
                                            transition: 'all .2s',
                                            '&:last-child td, &:last-child th': {
                                                border: 0
                                            },
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, .05)'
                                            }
                                        }}>
                                            <TableCell component="th" scope="row">
                                                3 ตัวบน
                                            </TableCell>
                                            <TableCell align="center">
                                                บาทละ
                                            </TableCell>
                                            <TableCell align="right">
                                                900
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{
                                            transition: 'all .2s',
                                            '&:last-child td, &:last-child th': {
                                                border: 0
                                            },
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, .05)'
                                            }
                                        }}>
                                            <TableCell component="th" scope="row">
                                                3 ตัวโต๊ด
                                            </TableCell>
                                            <TableCell align="center">
                                                บาทละ
                                            </TableCell>
                                            <TableCell align="right">
                                                150
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{
                                            transition: 'all .2s',
                                            '&:last-child td, &:last-child th': {
                                                border: 0
                                            },
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, .05)'
                                            }
                                        }}>
                                            <TableCell component="th" scope="row">
                                                2 ตัวบน
                                            </TableCell>
                                            <TableCell align="center">
                                                บาทละ
                                            </TableCell>
                                            <TableCell align="right">
                                                90
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{
                                            transition: 'all .2s',
                                            '&:last-child td, &:last-child th': {
                                                border: 0
                                            },
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, .05)'
                                            }
                                        }}>
                                            <TableCell component="th" scope="row">
                                                2 ตัวล่าง
                                            </TableCell>
                                            <TableCell align="center">
                                                บาทละ
                                            </TableCell>
                                            <TableCell align="right">
                                                90
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{
                                            transition: 'all .2s',
                                            '&:last-child td, &:last-child th': {
                                                border: 0
                                            },
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, .05)'
                                            }
                                        }}>
                                            <TableCell component="th" scope="row">
                                                วิ่งบน
                                            </TableCell>
                                            <TableCell align="center">
                                                บาทละ
                                            </TableCell>
                                            <TableCell align="right">
                                                3.2
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{
                                            transition: 'all .2s',
                                            '&:last-child td, &:last-child th': {
                                                border: 0
                                            },
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, .05)'
                                            }
                                        }}>
                                            <TableCell component="th" scope="row">
                                                วิ่งล่าง
                                            </TableCell>
                                            <TableCell align="center">
                                                บาทละ
                                            </TableCell>
                                            <TableCell align="right">
                                                4.2
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Box sx={{
                        backgroundColor: 'white',
                        color: 'black',
                        boxShadow: "0 4px 4px 2px rgba(0, 0, 0, 0.15)",
                        borderRadius: 2
                    }}>
                        <Box sx={{ p: 2 }}>
                            <Typography>
                                หวยหุ้นต่างประเทศ
                            </Typography>
                            <TableContainer>
                                <Table aria-label="simple table">
                                    <TableHead sx={{ backgroundColor: '#cf1f2d' }}>
                                        <TableRow>
                                            <TableCell>
                                                <Typography fontSize={14} color={'white'}>
                                                    ประเภท
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography fontSize={14} color={'white'}>
                                                    ราคา
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography fontSize={14} color={'white'}>
                                                    อัตราจ่าย
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow sx={{
                                            transition: 'all .2s',
                                            '&:last-child td, &:last-child th': {
                                                border: 0
                                            },
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, .05)'
                                            }
                                        }}>
                                            <TableCell component="th" scope="row">
                                                3 ตัวบน
                                            </TableCell>
                                            <TableCell align="center">
                                                บาทละ
                                            </TableCell>
                                            <TableCell align="right">
                                                900
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{
                                            transition: 'all .2s',
                                            '&:last-child td, &:last-child th': {
                                                border: 0
                                            },
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, .05)'
                                            }
                                        }}>
                                            <TableCell component="th" scope="row">
                                                3 ตัวโต๊ด
                                            </TableCell>
                                            <TableCell align="center">
                                                บาทละ
                                            </TableCell>
                                            <TableCell align="right">
                                                150
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{
                                            transition: 'all .2s',
                                            '&:last-child td, &:last-child th': {
                                                border: 0
                                            },
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, .05)'
                                            }
                                        }}>
                                            <TableCell component="th" scope="row">
                                                2 ตัวบน
                                            </TableCell>
                                            <TableCell align="center">
                                                บาทละ
                                            </TableCell>
                                            <TableCell align="right">
                                                90
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{
                                            transition: 'all .2s',
                                            '&:last-child td, &:last-child th': {
                                                border: 0
                                            },
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, .05)'
                                            }
                                        }}>
                                            <TableCell component="th" scope="row">
                                                2 ตัวล่าง
                                            </TableCell>
                                            <TableCell align="center">
                                                บาทละ
                                            </TableCell>
                                            <TableCell align="right">
                                                90
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{
                                            transition: 'all .2s',
                                            '&:last-child td, &:last-child th': {
                                                border: 0
                                            },
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, .05)'
                                            }
                                        }}>
                                            <TableCell component="th" scope="row">
                                                วิ่งบน
                                            </TableCell>
                                            <TableCell align="center">
                                                บาทละ
                                            </TableCell>
                                            <TableCell align="right">
                                                3.2
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{
                                            transition: 'all .2s',
                                            '&:last-child td, &:last-child th': {
                                                border: 0
                                            },
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, .05)'
                                            }
                                        }}>
                                            <TableCell component="th" scope="row">
                                                วิ่งล่าง
                                            </TableCell>
                                            <TableCell align="center">
                                                บาทละ
                                            </TableCell>
                                            <TableCell align="right">
                                                4.2
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default LottoRate;