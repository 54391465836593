import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, OutlinedInput, Typography } from "@mui/material";
import { inputNumberListType, listLimitType, lottoPoyType } from "../../types";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import ButtonBackground from "./../../assets/images/bg/button-keypad-type-123-light-bg.png";
import Swal from "sweetalert2";
import { ApiService } from "../../services/api.service";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";

interface PropsType {
    lotto_id: number;
    list: inputNumberListType[];
    openTabInputPrice: boolean;
    setOpenTabInputPrice: (state: boolean) => void;
    limitList: listLimitType[] | null;
}

const TabInputPriceLotto = ({ lotto_id, list, openTabInputPrice, setOpenTabInputPrice, limitList }: PropsType) => {
    const [loading, setLoading] = useState<boolean>(false);
    const cookies = new Cookies(null, { path: '/' });
    const navigate = useNavigate();
    const [groupedData, setGroupedData] = useState<{ [key: string]: inputNumberListType[] }>({});
    const priceRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const groupData = (data: inputNumberListType[]) => {
            let grouped: { [key: string]: inputNumberListType[] } = {};
            data.forEach((item) => {
                const groupKey = `${item.amountNumberState}-${item.subtype}`;

                if (!grouped[groupKey])
                {
                    grouped[groupKey] = [];
                }

                grouped[groupKey].push(item);
            });
            return grouped;
        };

        setGroupedData(groupData(list));
    }, [list]);

    useEffect(() => {
        const listFounded: string[] = [];

        limitList?.forEach((element: listLimitType) => {
            Object.entries(groupedData).forEach(([_group, items]) => {
                items.forEach((item) => {
                    if(item.number === element.number && item.amountNumberState === element.amountNumberState && item.subtype === element.subType)
                    {
                        listFounded.push(item.number)
                    }
                });
            });
        });

        if(listFounded.length > 0)
        {
            let text_show_change_number = 'ตัวเลข ';
            listFounded.forEach((element: string, index: number) => {
                text_show_change_number = `${text_show_change_number} ${(index + 1) !== listFounded.length ? `${element}, ` : `${element}`}`
            });
            text_show_change_number = `${text_show_change_number} มีการปรับเปลี่ยนอัตราจ่าย กรุณาตรวจสอบใหม่อีกครั้งค่ะ`
            Swal.fire({
                icon: 'warning',
                title: 'แจ้งเตือน',
                text: text_show_change_number
            });
        }
    }, [limitList]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if(!event.target)
        {
            return;
        }
        
        const element = event.target as HTMLInputElement;
        
        const inputId = element.id; // ดึง id ของ input
        const splitInputId = inputId.split("-");
        const showPriceElement = document.getElementById(`show-price-${splitInputId[2]}-${splitInputId[3]}-${splitInputId[4]}`) as HTMLElement;
        const newValue = element.value;
        const group = `${splitInputId[5]}-${splitInputId[6]}`;
        const multiple: number = group.split("-")[0] === "3" && group.split("-")[1] === "1"
            ? 900
            : group.split("-")[0] === "3" && group.split("-")[1] === "3"
            ? 150
            : group.split("-")[0] === "2" && group.split("-")[1] === "1"
            ? 90
            : group.split("-")[0] === "2" && group.split("-")[1] === "3"
            ? 90
            : group.split("-")[0] === "4" && group.split("-")[1] === "1"
            ? 3.2
            : group.split("-")[0] === "4" && group.split("-")[1] === "2"
            ? 4.2
            : 0
        if (/^\d*$/.test(newValue))
        {
            if(newValue)
            {
                element.value = newValue;
                const price: number = parseInt(element.value);
                if(showPriceElement)
                {
                    showPriceElement.innerHTML = `${price * multiple}`;
                }
            }
        }
        else
        {
            const numericValue = newValue.replace(/\D/g, '');
            if(element)
            {
                element.value = numericValue;
                const price: number = parseInt(element.value);
                if(showPriceElement)
                {
                    showPriceElement.innerHTML = `${price * multiple}`;
                }
            }
        }
    }

    const usePriceAllNumber = () => {
        if(!priceRef.current)
        {
            return;
        }
        else if(priceRef.current.value === '')
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'กรุณาใส่ราคาก่อนค่ะ'
            });
        }

        Object.entries(groupedData).forEach(([group, items]) => {
            items.forEach((item) => {
                const elementInput = document.getElementById(`input-price-${item.number}-${item.subtype}-${item.amountNumberState}-${group.split("-")[0]}-${group.split("-")[1]}`) as HTMLInputElement;
                if(elementInput && priceRef && priceRef.current)
                {
                    elementInput.value = priceRef.current.value;
                }
            });
        });
    }

    const usePriceAvgAllNumber = () => {
        let loop = true;

        while(loop)
        {
            if(!priceRef.current)
            {
                return;
            }
            else if(priceRef.current.value === '')
            {
                return Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: 'กรุณาใส่ราคาก่อนค่ะ'
                });
            }
            
            const price = priceRef.current.value;
            let lengthOfNumber = 0;
    
            Object.entries(groupedData).forEach(([_group, items]) => {
                lengthOfNumber = lengthOfNumber + items.length;
            });
            
            let totalPrice = 0;
            Object.entries(groupedData).forEach(([group, items]) => {
                const average = parseInt(price) / lengthOfNumber;
                const roundedAverage = Math.round(average);
    
                items.forEach((item) => {
                    totalPrice += roundedAverage;
                    const elementInput = document.getElementById(`input-price-${item.number}-${item.subtype}-${item.amountNumberState}-${group.split("-")[0]}-${group.split("-")[1]}`) as HTMLInputElement;
                    if(elementInput && priceRef && priceRef.current)
                    {
                        elementInput.value = `${roundedAverage}`;
                    }
                });
            });
    
            if(totalPrice > parseInt(price))
            {
                priceRef.current.value = `${parseInt(price) - 1}`;
                
            }
            else
            {
                loop = false;
            }
        }
    }

    const sendSubmit = async () => {
        const poy_data: lottoPoyType[] = [];
        let found_empty = false;

        Object.entries(groupedData).forEach(([group, items]) => {
            items.forEach((item) => {
                const elementInput = document.getElementById(`input-price-${item.number}-${item.subtype}-${item.amountNumberState}-${group.split("-")[0]}-${group.split("-")[1]}`) as HTMLInputElement;
                if(elementInput && priceRef && priceRef.current)
                {
                    if(!elementInput.value || elementInput.value === '')
                    {
                        found_empty = true;
                        return;
                    }
                    else
                    {
                        let pay: number = (group.split("-")[0] === "3" && group.split("-")[1] === "1" ? 900 :
                        group.split("-")[0] === "3" && group.split("-")[1] === "3" ? 150 :
                        group.split("-")[0] === "2" && group.split("-")[1] === "1" ? 90 :
                        group.split("-")[0] === "2" && group.split("-")[1] === "3" ? 90 :
                        group.split("-")[0] === "4" && group.split("-")[1] === "1" ? 3.2 :
                        group.split("-")[0] === "4" && group.split("-")[1] === "2" ? 4.2 : 0);

                        const findIndexPay = limitList ? limitList.findIndex((element: listLimitType) => {
                            return element.number === item.number && element.subType === item.subtype && element.amountNumberState === item.amountNumberState
                        }) : -1;
                        
                        if(findIndexPay != -1)
                        {
                            pay = limitList ? limitList[findIndexPay].pay : pay;
                        }

                        poy_data.push({
                            number: item.number,
                            subType: item.subtype,
                            amountNumberState: item.amountNumberState,
                            price: parseInt(elementInput.value),
                            pay: pay
                        });
                    }
                }
            })
        });

        if(found_empty)
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'กรุณากรอกราคาซื้อให้ครบทุกรายการค่ะ'
            });
        }

        try {
            setLoading(true);
            const service = new ApiService();
            const sendCreateBill = await service.createLottoBill(cookies.get('access_token'), lotto_id, poy_data);

            if(sendCreateBill.data.status_code === 200)
            {
                setLoading(false);
                return Swal.fire({
                    icon: 'success',
                    title: 'แจ้งเตือน',
                    text: 'สร้างบิลสำเร็จแล้วค่ะ'
                }).then(() => {
                    navigate('/');
                });
            }

            Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: `เกิดข้อผิดพลาด (${sendCreateBill.data.status_code} - ${sendCreateBill.data.message})`
            });
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    return (
        <Box sx={
            {
                display: openTabInputPrice ? 'block' : 'none',
                maxWidth: {
                    xs: `100%`,
                    sm: `100%`,
                    md: `100%`,
                    lg: `100%`
                },
                maxHeight: `100vh`,
                width: '100%',
                height: '100%',
                top: 0,
                opacity: openTabInputPrice ? 1 : 0,
                position: 'fixed',
                backgroundColor: 'white',
                zIndex: 100,
                color: 'white',
                transition: 'all .3s',
                borderLeft: 'solid 3px #e0e3e6',
                overflowY: 'scroll',
            }
        }>
            <Box width={`100%`} sx={
                {
                    backgroundColor: '#cf1f2d'
                }
            }>
                <Box p={1} display={`flex`} justifyContent={`space-between`}>
                    <Typography display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`}>
                        จัดการโพย
                    </Typography>
                    <Typography>
                        <Button size="small" sx={
                            {
                                px: 2,
                                backgroundColor: 'rgba(0, 0, 0, .5)',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, .7)',
                                    color: 'white',
                                }
                            }
                        } onClick={() => setOpenTabInputPrice(false)}>
                            <Typography fontSize={16} fontWeight={500}>
                                ปิดหน้าจัดการโพย
                            </Typography>
                        </Button>
                    </Typography>
                </Box>
            </Box>
            <Box width={`100%`}>
                <Box p={2} sx={{ color: 'black' }}>
                    <Grid container spacing={1}>
                        <Grid container spacing={2}>
                            {
                                Object.entries(groupedData).map(([group, items]) => (
                                    <Grid item xs={12} sm={6} md={4} lg={3} key={`${group}`}>
                                        <Box display="flex" flexDirection="column" height="100%">
                                            <Box
                                                bgcolor="#c4c4c4"
                                                px={2}
                                                py={0.5}
                                                display="flex"
                                                justifyContent="space-between"
                                            >
                                                <Typography fontSize={18}>
                                                    {group.split("-")[0] === "3" && group.split("-")[1] === "1"
                                                    ? "3 ตัวบน"
                                                    : group.split("-")[0] === "3" && group.split("-")[1] === "3"
                                                    ? "3 ตัวบนโต๊ด"
                                                    : group.split("-")[0] === "2" && group.split("-")[1] === "1"
                                                    ? "2 ตัวบน"
                                                    : group.split("-")[0] === "2" && group.split("-")[1] === "3"
                                                    ? "2 ตัวล่าง"
                                                    : group.split("-")[0] === "4" && group.split("-")[1] === "1"
                                                    ? "วิ่งบน"
                                                    : group.split("-")[0] === "4" && group.split("-")[1] === "2"
                                                    ? "วิ่งล่าง"
                                                    : group}
                                                </Typography>
                                                <Typography>({items.length} รายการ)</Typography>
                                            </Box>
                                            <Box
                                                p={2}
                                                flexGrow={1}
                                                sx={{ backgroundColor: "#f8f8f8", overflow: "auto" }}
                                            >
                                                {
                                                    items.map((item, index) => (
                                                        <Box display={`flex`} justifyContent={`space-between`} gap={1} key={index} mb={1}>
                                                            <Typography display={`flex`} alignContent={`center`} alignItems={`center`}>
                                                                {item.number}
                                                            </Typography>
                                                            <OutlinedInput
                                                                id={`input-price-${item.number}-${item.subtype}-${item.amountNumberState}-${group.split("-")[0]}-${group.split("-")[1]}`}
                                                                type="tel"
                                                                size="small"
                                                                autoComplete="off"
                                                                placeholder="ใส่ราคา"
                                                                onChange={handleChange}
                                                                sx={{
                                                                    "& .MuiOutlinedInput-input": {
                                                                        textAlign: "center", // จัดข้อความให้อยู่ตรงกลางแนวนอน
                                                                        padding: "8px 0", // ปรับ padding เพื่อให้ข้อความอยู่ตรงกลางแนวตั้ง
                                                                    },
                                                                    maxWidth: 75,
                                                                    maxHeight: 30,
                                                                    backgroundColor: "white",
                                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                                        borderColor: "#cf1f2d", // สีขอบขณะ default
                                                                    },
                                                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                                                        borderColor: "#cf1f2d", // สีขอบขณะ hover
                                                                    },
                                                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                                        borderColor: "#cf1f2d", // สีขอบขณะ focus
                                                                    }
                                                                }}
                                                            />
                                                            <Box sx={{
                                                                backgroundColor: '#eaeaea',
                                                                width: '100%',
                                                                maxWidth: '75px'
                                                            }}>
                                                                <Box py={.5} px={1}>
                                                                    <Typography align="center">
                                                                        X
                                                                        {
                                                                            group.split("-")[0] === "3" && group.split("-")[1] === "1"
                                                                            ? (limitList ? (
                                                                                limitList.findIndex((element: listLimitType) =>
                                                                                    element.number === item.number &&
                                                                                    element.amountNumberState === parseInt(group.split("-")[0]) &&
                                                                                    element.subType === parseInt(group.split("-")[1])
                                                                                ) !== -1 ? (limitList[limitList.findIndex((element: listLimitType) =>
                                                                                    element.number === item.number &&
                                                                                    element.amountNumberState === parseInt(group.split("-")[0]) &&
                                                                                    element.subType === parseInt(group.split("-")[1])
                                                                                )].pay) : "900"
                                                                            ) : "900")
                                                                            : group.split("-")[0] === "3" && group.split("-")[1] === "3"
                                                                            ? (limitList ? (
                                                                                limitList.findIndex((element: listLimitType) =>
                                                                                    element.number === item.number &&
                                                                                    element.amountNumberState === parseInt(group.split("-")[0]) &&
                                                                                    element.subType === parseInt(group.split("-")[1])
                                                                                ) !== -1 ? (limitList[limitList.findIndex((element: listLimitType) =>
                                                                                    element.number === item.number &&
                                                                                    element.amountNumberState === parseInt(group.split("-")[0]) &&
                                                                                    element.subType === parseInt(group.split("-")[1])
                                                                                )].pay) : "150"
                                                                            ) : "150")
                                                                            : group.split("-")[0] === "2" && group.split("-")[1] === "1"
                                                                            ? (limitList ? (
                                                                                limitList.findIndex((element: listLimitType) =>
                                                                                    element.number === item.number &&
                                                                                    element.amountNumberState === parseInt(group.split("-")[0]) &&
                                                                                    element.subType === parseInt(group.split("-")[1])
                                                                                ) !== -1 ? (limitList[limitList.findIndex((element: listLimitType) =>
                                                                                    element.number === item.number &&
                                                                                    element.amountNumberState === parseInt(group.split("-")[0]) &&
                                                                                    element.subType === parseInt(group.split("-")[1])
                                                                                )].pay) : "90"
                                                                            ) : "90")
                                                                            : group.split("-")[0] === "2" && group.split("-")[1] === "3"
                                                                            ? (limitList ? (
                                                                                limitList.findIndex((element: listLimitType) =>
                                                                                    element.number === item.number &&
                                                                                    element.amountNumberState === parseInt(group.split("-")[0]) &&
                                                                                    element.subType === parseInt(group.split("-")[1])
                                                                                ) !== -1 ? (limitList[limitList.findIndex((element: listLimitType) =>
                                                                                    element.number === item.number &&
                                                                                    element.amountNumberState === parseInt(group.split("-")[0]) &&
                                                                                    element.subType === parseInt(group.split("-")[1])
                                                                                )].pay) : "90"
                                                                            ) : "90")
                                                                            : group.split("-")[0] === "4" && group.split("-")[1] === "1"
                                                                            ? (limitList ? (
                                                                                limitList.findIndex((element: listLimitType) =>
                                                                                    element.number === item.number &&
                                                                                    element.amountNumberState === parseInt(group.split("-")[0]) &&
                                                                                    element.subType === parseInt(group.split("-")[1])
                                                                                ) !== -1 ? (limitList[limitList.findIndex((element: listLimitType) =>
                                                                                    element.number === item.number &&
                                                                                    element.amountNumberState === parseInt(group.split("-")[0]) &&
                                                                                    element.subType === parseInt(group.split("-")[1])
                                                                                )].pay) : "3.2"
                                                                            ) : "3.2")
                                                                            : group.split("-")[0] === "4" && group.split("-")[1] === "2"
                                                                            ? (limitList ? (
                                                                                limitList.findIndex((element: listLimitType) =>
                                                                                    element.number === item.number &&
                                                                                    element.amountNumberState === parseInt(group.split("-")[0]) &&
                                                                                    element.subType === parseInt(group.split("-")[1])
                                                                                ) !== -1 ? (limitList[limitList.findIndex((element: listLimitType) =>
                                                                                    element.number === item.number &&
                                                                                    element.amountNumberState === parseInt(group.split("-")[0]) &&
                                                                                    element.subType === parseInt(group.split("-")[1])
                                                                                )].pay) : "4.2"
                                                                            ) : "4.2")
                                                                            : "0"
                                                                        }
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                            <Box sx={{
                                                                backgroundColor: '#eaeaea',
                                                                width: '100%',
                                                                maxWidth: '75px'
                                                            }}>
                                                                <Box py={.5} px={1}>
                                                                    <Typography align="center" id={`show-price-${item.number}-${item.subtype}-${item.amountNumberState}`}>
                                                                        0
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    ))
                                                }
                                            </Box>
                                        </Box>
                                    </Grid>
                                ))
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ backgroundColor: '#cf1f2d', color: 'white', p: 2 }}>
                                จัดการทั้งโพย
                            </Box>
                            <Box sx={{ backgroundColor: '#f8f8f8', color: 'black', p: 2 }}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={3}>
                                        <OutlinedInput
                                            id="input_price"
                                            name="input_price"
                                            type={`tel`}
                                            size='small'
                                            fullWidth
                                            autoComplete="off"
                                            placeholder="ใส่ราคา (บาท)"
                                            inputRef={priceRef}
                                            onChange={handleChange}
                                            sx={
                                                {
                                                    backgroundColor: 'white'
                                                }
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Button fullWidth
                                            sx={{
                                                backgroundColor: 'none',
                                                backgroundImage: `url('${ButtonBackground}')`,
                                                backgroundSize: 'cover',
                                                border: '1px solid #bfbebe',
                                                borderRadius: '4px',
                                                transition: 'all .2s',
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    backgroundColor: '#f2f7ff',
                                                    fontWeight: 'bold',
                                                }
                                            }}
                                            disableRipple
                                            disableFocusRipple
                                            onClick={usePriceAllNumber}
                                        >
                                            <Typography color={'white'} fontWeight={`bold`}>
                                                ใส่ราคานี้กับทุกรายการ
                                            </Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Button fullWidth
                                            sx={{
                                                backgroundColor: 'none',
                                                backgroundImage: `url('${ButtonBackground}')`,
                                                backgroundSize: 'cover',
                                                border: '1px solid #bfbebe',
                                                borderRadius: '4px',
                                                transition: 'all .2s',
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    backgroundColor: '#f2f7ff',
                                                    fontWeight: 'bold',
                                                }
                                            }}
                                            disableRipple
                                            disableFocusRipple
                                            onClick={usePriceAvgAllNumber}
                                        >
                                            <Typography color={'white'} fontWeight={`bold`}>
                                                หารราคานี้เฉลี่ยทุกรายการ
                                            </Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Button fullWidth
                                sx={{
                                    backgroundColor: 'none',
                                    backgroundImage: `url('${ButtonBackground}')`,
                                    backgroundSize: 'cover',
                                    border: '1px solid #bfbebe',
                                    borderRadius: '4px',
                                    transition: 'all .2s',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: '#f2f7ff',
                                        fontWeight: 'bold',
                                    },
                                }}
                                disableRipple
                                disableFocusRipple
                                onClick={() => sendSubmit()}
                            >
                                {
                                    !loading ? <Typography color={'white'} fontWeight={`bold`}>
                                        ส่งโพย
                                    </Typography> : <CircularProgress size={20} sx={
                                        {
                                            color: 'white'
                                        }
                                    } />
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    )
}

export default TabInputPriceLotto;